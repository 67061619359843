import {Component} from "react";
import _ from 'lodash';

export default class StateComponent extends Component {
  currentStateCallbackTimeout;
  static usedModules = [];
  modules = [];

  onStateCallbacks = {};
  onceStateCallbacks = {};

  static shuffleAnswers(state) {
    for (let question of state.questions) {
      question.answers = _.shuffle(question.answers);
    }
  }

  static processAnswers(state, defaultValues) {
    for (let question of state.questions) {
      for (let answer of question.answers) {
        _.defaults(answer, defaultValues);
      }
    }
  }

  constructor(props, startingState=0) {
    super(props);

    this.state = {
      current: startingState,
    };
  }

  componentWillUnmount() {
    clearTimeout(this.currentStateCallbackTimeout);
  }

  setCurrentState(newState, callback = () => {}, callbackDelay) {
    clearTimeout(this.currentStateCallbackTimeout);
    let callbackFunction = callback;

    if (callbackDelay) {
      callbackFunction = () => { this.currentStateCallbackTimeout = setTimeout(callback, callbackDelay) }
    }

    this.setState({
      current: newState
    }, () => {
      this.checkStateCallbacks(newState);
      callbackFunction()
    });
  }

  checkStateCallbacks(state) {
    if (state in this.onStateCallbacks) {
      for (const callback of this.onStateCallbacks[state]) {
        callback();
      }
    }

    if (state in this.onceStateCallbacks) {
      for (const callback of this.onceStateCallbacks[state]) {
        callback();
      }

      delete this.onceStateCallbacks[state];
    }
  }

  onState(state, callback) {
    if (!(state in this.onStateCallbacks)) {
      this.onStateCallbacks[state] = [];
    }

    this.onStateCallbacks[state].push(callback);
  }

  onceState(state, callback) {
    if (!(state in this.onceStateCallbacks)) {
      this.onceStateCallbacks[state] = [];
    }

    this.onceStateCallbacks[state].push(callback);
  }


  delayedSetCurrentState(newState, delayMs, callback = () => {}, callbackDelay) {
    setTimeout(this.setCurrentState.bind(this, newState, callback, callbackDelay), delayMs);
  }

  setCurrentStateDelayed = this.delayedSetCurrentState;

  setCurrentStateSequence(stateList, delayMs, callback = () => {}, callbackDelay) {
    let stateStack = stateList.slice(0).reverse();
    this.setCurrentStateStack(stateStack, delayMs, callback, callbackDelay);
  }

  setCurrentStateStack(stateStack, delayMs, callback = () => {}, callbackDelay) {
    let currentState = stateStack.pop();
    if (stateStack.length > 0) {
      this.setCurrentState(currentState, () => {
        setTimeout(this.setCurrentStateStack.bind(this, stateStack, delayMs, callback), delayMs);
      });
    } else {
      this.setCurrentState(currentState, callback, callbackDelay);
    }
  }

  inState = (state) => {
    if (Array.isArray(state)) {
      return state.includes(this.state.current)
    } else {
      return this.state.current === state
    }
  };

  inStates = this.inState;
}