import React, {Component} from "react";
import Velocity from "velocity-animate";
import PropTypes from "prop-types";
import _ from "lodash";
import classNames from 'classnames';

import PlayArea from "../../../lib/PlayArea";
import {emString} from "../../../utils/styling";

import {
  ANSWER_HEIGHT_EMS,
  ANSWER_WIDTH_EMS,
  FLIGHT_DURATION,
  MOVEMENT_HORIZONTAL,
  MOVEMENT_VERTICAL
} from "../RiseFallExercise";

import './RiseFallCard.scss';
import AnimationCorrectExplosion from "animations/AnimationCorrectExplosionNew/AnimationCorrectExplosion";
import AnimationIncorrectExplosion from "animations/AnimationIncorrectExplosion/AnimationIncorrectExplosion";

export default class RiseFallCard extends Component {
  static propTypes = {
    question: PropTypes.string,
    answer: PropTypes.object,
    moving: PropTypes.bool,

    index: PropTypes.number,
    clickCallback: PropTypes.func,

    goNextAction: PropTypes.func,
  };

  static defaultProps = {

  };

  startedMoving = false;
  attachmentRef;

  constructor(props) {
    super(props);

    this.state = {
      quickHide: false,

      showFeedback: false,
      isCorrect: props.answer.correct,

      feedbackPosition: {
        x: 0,
        y: 0,
      }
    };

    this.elementRef = React.createRef();
    this.attachmentRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (!this.startedMoving && this.props.moving) {
      this.startMoving();
    } else if (this.startedMoving) {
      if (prevProps.moving !== this.props.moving) {
        if (this.props.moving) {
          this.resumeAnimation();
        } else {
          this.pauseAnimation();
        }
      }

      if (!this.props.answer.visible) {
        this.startedMoving = false;
      }
    }
  }


  clicked = () => {
    if (this.props.answer.visible && this.props.answer.active) {
      this.props.clickCallback(this.props.answer, this.props.index);

      this.setState({
        showFeedback: true,
        feedbackPosition: {
          x: this.attachmentRef.current.getBoundingClientRect().width / 2,
          y: -this.attachmentRef.current.getBoundingClientRect().height / 2,
        },
      })
    }
  };

  _getMovementInEms = () => {
    if (PlayArea.isVertical()) {
      return -MOVEMENT_VERTICAL
    } else {
      return -MOVEMENT_HORIZONTAL
    }
  };

  pauseAnimation = () => {
    if (this.props.answer.timeout) {
      this.props.answer.timeout.pause()
    }

    Velocity(
      this.elementRef.current,
      "pause",
    );
  };

  resumeAnimation = () => {
    if (this.props.answer.timeout) {
      this.props.answer.timeout.resume()
    }

    Velocity(
      this.elementRef.current,
      "resume",
    );
  };

  startMoving = () => {
    this.startedMoving = true;
    this.resumeAnimation();
    let translateVector = this.props.answer.falling ?
      [0, emString(this._getMovementInEms())] :
      [emString(this._getMovementInEms()), 0];
    Velocity(
      this.elementRef.current,
      {
        translateY: translateVector,
      },
      {
        duration: FLIGHT_DURATION,
      }
    );
    this.resumeAnimation()
  };

  render() {
    let answer = this.props.answer
    let style = _.clone(this.props.style)
    if (this.state.quickHide && style) {
      style['WebkitTransition'] = 'none'
      style['MozTransition'] = 'none'
      style['OTransition'] = 'none'
      style['transition'] = 'none'
      style['opacity'] = 0
    }

    let bubbleContainerStyle = {
      width: emString(ANSWER_WIDTH_EMS),
      height: emString(ANSWER_HEIGHT_EMS),
    };

    let animationElement;
    if (answer.correct) {
      animationElement = <AnimationCorrectExplosion visible={this.state.showFeedback}
        x={this.state.feedbackPosition.x} y={this.state.feedbackPosition.y}
      />;
    } else {
      animationElement = <AnimationIncorrectExplosion visible={this.state.showFeedback}
        x={this.state.feedbackPosition.x} y={this.state.feedbackPosition.y}
      />;
    }

    return (
      <div className={classNames(
        "RiseFallCard",
        {
          "rising": !answer.falling,
          "falling": answer.falling,
          "correct": answer.correct,
          "incorrect": !answer.correct,
          "show-feedback": this.state.showFeedback,
        })}
        style={style} ref={this.elementRef}
      >
        {this.state.showFeedback && animationElement}
        <div className={"attachment"} style={{width: emString(ANSWER_WIDTH_EMS)}} ref={this.attachmentRef} />
        <div className={classNames('answer', {
            'visible': answer.visible,
            'active': answer.active,
            'show-feedback': answer.showFeedback,
          })}
          style={bubbleContainerStyle}
          onClick={this.clicked}
        >
          <div className="content">{answer.content}</div>
          <div className={classNames("feedback", {
              success: answer.correct,
              error: !answer.correct,
            })} style={{marginTop: -ANSWER_HEIGHT_EMS + 'em'}}
          >
            <i className={'fas fa-' + (answer.correct ? 'check' : 'times')} />
          </div>
        </div>
      </div>
    )
  }
}