import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';
import Draggable from 'react-draggable';
import _ from 'underscore';
import mojs from 'mo-js';

import Card from "components/Card";

import './css/BasketsExercise.scss'
import PointsBar from "components/PointsBar";

const ANIMATION_SPEED = 1000;
const TIME_LIMIT = 60;

export default class BasketsExercise extends Component {
  answerImageCards = {};
  exerciseRef;
  maxPoints = 0;

  burstCorrect = new mojs.Burst({
    left: 0, top: 0,
    radius:   { 0: 100 },
    angle:    'rand(0, 360)',
    count:    10,
    timeline: { delay: 0 },
    children: {
      shape: 'line',
      radius:       10,
      strokeLinecap: 'round',
      stroke: { 'rgb(255, 120, 0)': 'rgb(0, 198, 24)'  },
      strokeWidth: 5,
      fill:         { 'rgb(255, 120, 0)': 'rgb(0, 198, 24)'  },
      scale:        { 1: 0, easing: 'quad.in' },
      pathScale:    [ .8, null ],
      degreeShift:  [ 13, null ],
      duration:     [ 700, 700 ],
      easing:       'quint.out'
    },
    onStart: () => {
      this.burstCorrect.el.style.zIndex = 12;
    },
    onComplete: () => {
      this.burstCorrect.el.style.zIndex = -1;
    },
  });

  burstIncorrect = new mojs.Burst({
    left: 0, top: 0,
    radius:   { 0: 100 },
    angle:    'rand(0, 360)',
    count:    10,
    timeline: { delay: 0 },
    children: {
      shape: 'line',
      radius:       10,
      strokeLinecap: 'round',
      stroke: { 'rgb(255, 120, 0)': 'rgb(255, 13, 0)'  },
      strokeWidth: 5,
      fill:         { 'rgb(255, 120, 0)': 'rgb(255, 13, 0)'  },
      scale:        { 1: 0, easing: 'quad.in' },
      pathScale:    [ .8, null ],
      degreeShift:  [ 13, null ],
      duration:     [ 700, 700 ],
      easing:       'quint.out'
    },
    onStart: () => {
      this.burstCorrect.el.style.zIndex = 12;
    },
    onComplete: () => {
      this.burstCorrect.el.style.zIndex = -1;
    },
  });

  static propTypes = {
    question: PropTypes.string,
    questions: PropTypes.array,
    answers: PropTypes.array,
    answersType: PropTypes.string,
    goNextAction: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: true,

      clockRunning: false,
      timeRanOut: false,
      gameFinished: false,
      points: 0,
      playing: true,
      timeout: false,

      feedback: {
        shown: false,
        type: 'correct',
        content: '',
      },

      answers: props.answers,
      questions: props.questions,
      currentTextCardIndex: 0,

      changed: 0,
    };

    this.maxPoints = this.state.questions.length * 2;

    // this.state.questions = _.shuffle(this.state.questions);

    for (let index in this.state.questions) {
      if (this.state.questions.hasOwnProperty(index)) {
        this.state.questions[index] = _.extend(this.state.questions[index], {
          active: true,
          visible: true,
          index: index,
          success: false,
          showFeedback: false,
          timeout: null,
        });
      }
    }

    this.answerChosen = this.answerChosen.bind(this);
    this.goNext = this.goNext.bind(this);

    this._goNext = this._goNext.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this._updateDimensions);
    this._updateDimensions();
    this._updateAnswersPosition();
  }

  componentDidUpdate() {
    this._updateAnswersPosition();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._updateDimensions);
  }

  _updateDimensions = () => {
    let burstRadius = this.exerciseRef.offsetWidth / 10;
    this.burstCorrect.tune({
      radius: { 0: burstRadius },
      children: {
        radius: burstRadius / 10,
        strokeWidth: burstRadius / 20,
      }
    });
    this.burstIncorrect.tune({
      radius: { 0: burstRadius },
      children: {
        radius: burstRadius / 10,
        strokeWidth: burstRadius / 20,
      }
    });
  };

  _updateAnswersPosition = () => {
    for (let index in this.answerImageCards) {
      if (this.answerImageCards.hasOwnProperty(index)) {
        let answerImageCard = this.answerImageCards[index];
        let cardRect = answerImageCard.card.DOM.getBoundingClientRect();

        answerImageCard['position'] = {
          topLeft: {
            x: cardRect.x,
            y: cardRect.y
          },
          bottomRight: {
            x: cardRect.x + cardRect.width,
            y: cardRect.y + cardRect.height
          }
        }
      }
    }
  };

  answerChosen(question, answer, position) {
    if (!this.state.playing) {
      return;
    }

    let feedbackType, feedbackContent;
    let pointChange = 0;
    let feedbackTime = 3000;

    if (question.answerId === answer.id) {
      // correct
      feedbackType = 'correct';
      feedbackContent = question.correctFeedback;
      pointChange = 2;

      this.burstCorrect.tune({
        x: position[0],
        y: position[1],
      }).replay();
      new Audio('/sounds/success.mp3').play();
    } else {
      // error
      feedbackType = 'incorrect';
      feedbackContent = question.incorrectFeedback;
      feedbackTime = 5000;

      this.burstIncorrect.tune({
        x: position[0],
        y: position[1],
      }).replay();
      new Audio('/sounds/error.mp3').play();
    }

    this.setState((prevState) => {
      let timeout = setTimeout(this.hideFeedback, feedbackTime);

      let feedback = {
        shown: true,
        type: feedbackType,
        content: feedbackContent,
      };
      prevState.questions[question.index]['visible'] = false;
      prevState.questions[question.index]['active'] = false;

      return {
        feedback: feedback,
        points: prevState.points + pointChange,
        answers: prevState.answers,
        timeout: timeout,
        playing: false,
      }
    });
  }

  hideFeedback = () => {
    this.setState((prevState) => {
      prevState.feedback.shown = false;
      let playing = false;

      for (let question of this.state.questions) {
        if (question.active) {
          playing = true;
        }
      }

      return {
        playing: playing,
        finished: !playing,
        feedback: prevState.feedback,
      }
    });
  };

  goNext() {
    new Audio('/sounds/click.mp3').play();
    this.setState({
      visible: false,
    });
    setTimeout(this._goNext, ANIMATION_SPEED);
  }

  _goNext() {
    this.props.goNextAction(this.state.points);
  }

  timeRanOut = () => {
    this.setState((prevState) => {
      for (let question of prevState.questions) {
        question.active = false;
        question.visible = false;
      }

      return {
        playing: false,
        finished: true,
        timeRanOut: true,
        clockRunning: false,
      }
    });
  };

  questionDragged = (event, target, question) => {
    this.setState({
      clockRunning: true,
    });

    let position = [event.clientX, event.clientY];

    let node = target.node.getBoundingClientRect();
    let [x, y, width, height] = [node.x, node.y, node.width, node.height];
    let corners = [
      { x: x, y: y },
      { x: x + width, y: y },
      { x: x, y: y + height },
      { x: x + width, y: y + height },
    ];

    let answer = this._findImageCard(...position);
    if (!answer) {
      answer = this._findCrossingImageCard(corners);
    }

    if (answer) {
      this.answerChosen(question, answer, position);
    }
  };

  _findImageCard = (x, y)  => {
    for (let imageCard of Object.values(this.answerImageCards)) {
      if (imageCard.position) {
        if ((imageCard.position.topLeft.x < x && x < imageCard.position.bottomRight.x)
          && (imageCard.position.topLeft.y < y && y < imageCard.position.bottomRight.y)
        ) {
          return imageCard.answer;
        }
      }
    }

    return false;
  };

  _findCrossingImageCard = (corners) => {
    let [answer, newAnswer] = [false, false];
    for (let corner of corners) {
      newAnswer = this._findImageCard(corner.x, corner.y);
      if (answer && newAnswer && newAnswer.id !== answer.id) {
        answer = false;
        break;
      } else if (newAnswer) {
        answer = newAnswer;
      }
    }

    return answer;
  };

  render() {
    let answersRow = this.state.answers.map((answer, index) => {
      return (
        <Card classes="answer" key={index}
              saveDOM={true} ref={(element) => {this.answerImageCards[answer.id] = {card: element, answer: answer}}}>
          <p>{ answer.content }</p>
          <img src={'images/baskets/' + answer.image + '.jpg'} alt={'Zdjęcie ' + answer.content} />
        </Card>
      );
    });

    let questionStyle = {
      width: this.state.questions.length > 6 ? '23%' : '26%',
    };

    let questionsRow = this.state.questions.map((question, index) => {
      return (
        <Draggable
          handle='.question'
          defaultPosition={{x: 0, y: 0}}
          position={{x: 0, y: 0}}
          onStop={(e, t) => this.questionDragged(e, t, question)}
          disabled={!this.state.playing}

          key={index}
        >
          <div className='question' style={questionStyle}>
            <Card classes={this.state.playing ? '' : 'disabled'} answer={question} side={question.side} hidden={!question.visible}>
              <p>
                {question.content}
              </p>
            </Card>
          </div>
        </Draggable>
      )
    });

    let feedbackCard = (
      <Card classes={'feedback ' + this.state.feedback.type}>
        <p>{ this.state.feedback.content }</p>
      </Card>
    );

    return (
      <CSSTransitionGroup
        transitionName="example"
        transitionAppear={true}
        transitionAppearTimeout={ANIMATION_SPEED}
        transitionEnter={true}
        transitionEnterTimeout={ANIMATION_SPEED}
        transitionLeave={true}
        transitionLeaveTimeout={ANIMATION_SPEED}>
        {this.state.visible &&
        <div className="BasketsExercise" ref={input => {this.exerciseRef = input}}>
          <div className="pure-g points-bar-container">
            <div className="pure-u-1-1">
              <PointsBar
                points={this.state.points} maxPoints={this.maxPoints}
                timeLimit={TIME_LIMIT} clockRunning={this.state.clockRunning && this.state.playing} onTimeRanOut={this.timeRanOut}
                instruction={this.props.instruction}
              />
            </div>
          </div>
          <div className="pure-g answers-container">
            <CSSTransitionGroup
              transitionName="example"
              transitionAppear={true}
              transitionAppearTimeout={ANIMATION_SPEED}
              transitionEnter={true}
              transitionEnterTimeout={ANIMATION_SPEED}
              transitionLeave={true}
              transitionLeaveTimeout={ANIMATION_SPEED}>
              {this.state.gameFinished && this.state.timeRanOut &&
              <Card classes='timeFinished pure-u-1-1'>
                <h1>Koniec czasu!</h1>
              </Card>
              }
            </CSSTransitionGroup>
            <div className='answers-row pure-u-1-1'>
              {answersRow}
            </div>
            <div className='questions-row pure-u-1-1'>
              {questionsRow}
            </div>
          </div>
          <div className="pure-g feedback-container">
            <div className={'pure-u-1-1'}>
              <CSSTransitionGroup
                transitionName="example"
                transitionEnter={true}
                transitionEnterTimeout={500}
                transitionLeave={true}
                transitionLeaveTimeout={500}>
                {this.state.feedback.shown &&
                  feedbackCard
                }
              </CSSTransitionGroup>
            </div>
          </div>
          <CSSTransitionGroup
            transitionName="example"
            transitionEnter={true}
            transitionEnterTimeout={ANIMATION_SPEED}
            transitionLeave={true}
            transitionLeaveTimeout={ANIMATION_SPEED}>
            {this.state.gameFinished &&
            <div className="pure-g buttons-container">
              <div className="pure-u-1-1 center">
                <Card classes="next-button" onClick={this.goNext}>
                  <p>Przejdź dalej</p>
                </Card>
              </div>
            </div>
            }
          </CSSTransitionGroup>
        </div>
        }
      </CSSTransitionGroup>
    );
  }
}
