import React from "react"
import _, {defaultTo} from "lodash";
import Velocity from "velocity-animate";
import classNames from 'classnames';

import ExerciseComponent from "base/ExerciseComponent";
import Sounds from 'lib/Sounds'

import Card from "components/Card/Card";
import AnimatedElement from "components/AnimatedElement/AnimatedElement";
import Button from "components/Button/Button";
import InstructionCard from "components/InstructionCard/InstructionCard";
import FeedbackCard from "components/FeedbackCard/FeedbackCard";
import AnimationCorrectExplosion from "animations/AnimationCorrectExplosionNew/AnimationCorrectExplosion";
import AnimationIncorrectExplosion from "animations/AnimationIncorrectExplosion/AnimationIncorrectExplosion";

import "./TrainExercise.scss"
import {DEFAULT_STATES} from "base/ExerciseComponent";
import ChosenAnswerStatsModule from "../../exercises/modules/stats/ChosenAnswerStatsModule";
import {DEFAULT_ANIMATION_SPEED} from "../../controllers/SprintController";

const ANIMATION_SPEED = 1000;
const TIME_BETWEEN_STATIONS = 3;
const TIME_FOR_FIRST_ANSWER = 8;
const TIME_FOR_SECOND_ANSWER = 4;
export const POINTS_FOR_FIRST_ANSWER = 4;
const POINTS_FOR_SECOND_ANSWER = 2;
const TIME_FOR_COOLDOWN = 1;
const TIME_FOR_FEEDBACK = 5;

const STATES = {
  ...DEFAULT_STATES,
  DRIVING_TO_NEXT_QUESTION: 1,
  ON_THE_STATION: 2,
  STATION_CHOSEN: 3,
  QUESTION_HIDING: 4,
};

const QUESTION_VISIBLE_STATES = [
  STATES.DRIVING_TO_NEXT_QUESTION,
  STATES.ON_THE_STATION,
  STATES.STATION_CHOSEN,
];


export default class TrainExercise extends ExerciseComponent {
  static exerciseClass = "TrainExercise";
  instruction = "Wysiądź na stacji z prawidłową odpowiedzią";

  timeout = null;
  nextStationTimeout = null;

  chosenAnswerIds = [];

  timeLimit = 0;

  chooseAnswerRef;

  static maxPoints(questions, parameters) {
    return questions.length * defaultTo(parameters['pointsForFirstAnswer'], POINTS_FOR_FIRST_ANSWER);
  }

  constructor(props) {
    super(props);

    let {timeForFirstAnswer, timeForSecondAnswer, pointsForFirstAnswer, pointsForSecondAnswer} = props.parameters;
    timeForFirstAnswer = timeForFirstAnswer ? timeForFirstAnswer : TIME_FOR_FIRST_ANSWER;
    timeForSecondAnswer = timeForSecondAnswer ? timeForSecondAnswer : TIME_FOR_SECOND_ANSWER;
    pointsForFirstAnswer = pointsForFirstAnswer ? pointsForFirstAnswer : POINTS_FOR_FIRST_ANSWER;
    pointsForSecondAnswer = pointsForSecondAnswer ? pointsForSecondAnswer : POINTS_FOR_SECOND_ANSWER;

    this.state = {
      ...this.state,
      current: STATES.STARTING,

      points: 0,
      secondPass: false,

      clockId: 0,

      timeForFirstAnswer,
      timeForSecondAnswer,
      pointsForFirstAnswer,
      pointsForSecondAnswer,

      currentQuestionIndex: -1,
      currentAnswerIndex: -1,
      answers: [],
    };

    this.stationRef = React.createRef();
    this.railsRef = React.createRef();
    this.chooseAnswerRef = React.createRef();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.nextStationTimeout);
  }

  usedModules(questions, parameters) {
    return [
      new ChosenAnswerStatsModule({
        resetTimestampInStates: [STATES.ON_THE_STATION],
      }, questions, parameters),
    ]
  }

  renderExercise(state, props) {
    let answer = this.getCurrentAnswer();
    let animationElement;
    if (this.chooseAnswerRef.current) {
      const buttonRect = this.chooseAnswerRef.current.buttonRef.current.getBoundingClientRect();
      let feedbackPosition = {
        x: buttonRect.width / 2,
        y: buttonRect.height / 2,
      };
      if (answer.correct) {
        animationElement = <AnimationCorrectExplosion visible={this.inState(STATES.STATION_CHOSEN)}
          x={feedbackPosition.x} y={feedbackPosition.y}
        />;
      } else {
        animationElement = <AnimationIncorrectExplosion visible={this.inState(STATES.STATION_CHOSEN)}
          x={feedbackPosition.x} y={feedbackPosition.y}
        />;
      }
    }

    return <>
      <AnimatedElement visible={this.inStates(QUESTION_VISIBLE_STATES)} animation={AnimatedElement.AnimationTypes.slideLeft} durationMs={ANIMATION_SPEED}>
        <InstructionCard
          countType="Pytanie" countCurrent={this.currentQuestionCount()} countMax={this.props.questions.length}
          mainText={this.currentQuestionContent()}
        />
      </AnimatedElement>
      <FeedbackCard key="feedback" visible={this.inState(STATES.STATION_CHOSEN)}
        content={answer.parameters ? answer.parameters.feedback : ''} successful={answer.correct}/>
      <AnimatedElement visible={this.inStates(QUESTION_VISIBLE_STATES)}>
        <div className={classNames("train-container", {"second-pass": this.state.secondPass})}>
          <div className="animated-element station" ref={this.stationRef} />
          <div className="animated-element train" />
          <div className="animated-element rails" ref={this.railsRef} />
        </div>
      </AnimatedElement>
      <AnimatedElement visible={this.inStates([STATES.ON_THE_STATION, STATES.STATION_CHOSEN])}>
        <div className="answer-container">
          <Card className="answer" color={Card.COLORS.BRIGHT}>
            <div>
              <p className='answer-count'>Stacja {this.state.currentAnswerIndex + 1} z { this.state.answers.length }</p>
              <p className='answer-content'>{ answer.content }</p>
            </div>
          </Card>
          <div className="actions">
            <Button onClick={this.nextStation}>
              Następna stacja
            </Button>
            <Button onClick={this.answerChosen} ref={this.chooseAnswerRef}
              big={true}
            >
              { animationElement }
              Wysiadam
            </Button>
          </div>
        </div>
      </AnimatedElement>
    </>
  }

  startGame = () => {
    this.nextQuestion();
  };

  currentQuestionCount = () => {
    return this.state.currentQuestionIndex + 1;
  };

  currentQuestionContent = () => {
    let question = this.getCurrentQuestion();
    if (question) {
      return question.content;
    } else {
      return '';
    }
  };

  getCurrentQuestion = () => {
    return this.props.questions[this.state.currentQuestionIndex];
  };

  showAnswer = () => {
    this.setState((prevState) => {
      let index = prevState.currentAnswerIndex + 1;
      if (index >= this.state.answers.length) {
        index = 0;
      }

      return {
        currentAnswerIndex: index,
      }
    }, () => {this.setCurrentState(STATES.ON_THE_STATION)});

    let timeForAnswer = this.state.timeForFirstAnswer;
    if (this.state.secondPass) {
      timeForAnswer = this.state.timeForSecondAnswer;
    }

    this.nextStationTimeout = setTimeout(this.nextStation, timeForAnswer * 1000)
  };

  nextStation = () => {
    clearTimeout(this.nextStationTimeout);
    let lastAnswer = false;

    this.setCurrentState(STATES.DRIVING_TO_NEXT_QUESTION, () => {
      this.setState((state) => {
        let {secondPass, currentAnswerIndex, answers} = state;
        let nextAnswerIndex = currentAnswerIndex + 1;
        if (nextAnswerIndex >= answers.length) {
          if (!secondPass) {
            secondPass = true;
          } else {
            lastAnswer = true
          }
        }

        return {
          secondPass,
        }
      }, () => {
        if (lastAnswer) {
          this.timeRanOut();
        } else {
          this.timeout = setTimeout(this.moveRails.bind(this, TIME_BETWEEN_STATIONS * 1000, this.showAnswer), TIME_FOR_COOLDOWN * 1000);
        }
      });

    });
  };

  timeRanOut = () => {
    clearTimeout(this.timeout);
    clearTimeout(this.nextStationTimeout);
    Sounds.error.play();
    this.timeout = setTimeout(this.nextQuestion, 1000);
  };

  nextQuestion = () => {
    this.setState((state) => {
      if (state.currentQuestionIndex < this.props.questions.length - 1) {
        this.timeout = setTimeout(this.questionChanged, DEFAULT_ANIMATION_SPEED)
      } else {
        this.timeout = this.setCurrentStateDelayed(STATES.FINISHING, DEFAULT_ANIMATION_SPEED);
      }

      return {
        secondPass: false,
      }
    }, () => {this.setCurrentState(STATES.QUESTION_HIDING)})
  };

  questionChanged = () => {
    let newQuestionIndex = this.state.currentQuestionIndex + 1;
    let question = this.props.questions[newQuestionIndex];
    super._questionAppeared(question);

    this.setState({
      currentQuestionIndex: newQuestionIndex,
      currentAnswerIndex: -1,
      answers: _.shuffle(question.answers),
    }, () => {
      this.nextStation();
      this.setCurrentState(STATES.DRIVING_TO_NEXT_QUESTION)
    });
  };

  getCurrentAnswer = () => {
    if (this.state.currentAnswerIndex < 0)
      return '';
    else
      return this.state.answers[this.state.currentAnswerIndex]
  };

  answerChosen = () => {
    clearTimeout(this.timeout);
    clearTimeout(this.nextStationTimeout);

    this.setState((prevState) => {
      let pointsChange = 0;
      let answer = this.getCurrentAnswer();
      super._answerChosen(answer);

      this.chosenAnswerIds.push(answer.id);

      if (answer.correct) {
        Sounds.success.play();
        pointsChange = prevState.secondPass ? this.state.pointsForSecondAnswer : this.state.pointsForFirstAnswer;
      } else {
        Sounds.error.play()
      }

      this.timeout = setTimeout(this.nextQuestion, TIME_FOR_FEEDBACK * 1000);

      return {
        points: prevState.points + pointsChange,
      }
    }, () => {this.setCurrentState(STATES.STATION_CHOSEN)})
  };

  moveRails = (duration, onArrival) => {
    this.moveFromStation(duration / 2);
    this.moveToStation(duration / 2);
    this.timeout = setTimeout(onArrival, duration);
  };

  moveToStation = (duration) => {
    Velocity(
      this.railsRef.current, {
        backgroundPositionX: ['60em', '150em'],
      }, {
        duration: duration,
        easing: "easeOutSine",
      }
    );
    Velocity(
      this.stationRef.current, {
        backgroundPositionX: ['60em', '150em'],
      }, {
        duration: duration,
        easing: "easeOutSine",
      }
    )
  };

  moveFromStation = (duration) => {
    Velocity(
      this.railsRef.current, {
        backgroundPositionX: ['-30em', '60em'],
      }, {
        duration: duration,
        easing: "easeInSine",
      }
    );
    Velocity(
      this.stationRef.current, {
        backgroundPositionX: ['-30em', '60em'],
      }, {
        duration: duration,
        easing: "easeInSine",
      }
    )
  };
  
  goNext = () => {
    if (this.inState(STATES.FINISHING)) {
      this.setCurrentState(STATES.FINISHED, () => {
        setTimeout(this._goNext, ANIMATION_SPEED)
      });
    }
  };

  _goNext = () => {
    this.props.goNextAction({
      points: this.state.points,
      other: {
        chosenAnswerIds: this.chosenAnswerIds,
      }
    })
  };
}