import React from 'react';
import propTypes from 'prop-types';
import ReactMarkdown from "react-markdown";
import classNames from 'classnames';
import validate from 'validate.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import _ from 'lodash';

import ExerciseComponent from "base/ExerciseComponent";
import './ChoicesExercise.scss';
import AnimatedElement from "components/AnimatedElement/AnimatedElement";
import InstructionCard from "components/InstructionCard";
import Card from "components/Card/Card";
import Button from "components/Button/Button";

const DEFAULT_ANSWERS_LIMIT = 3;
const DEFAULT_SHOULD_SEND_EMAIL_WITH_COMMENTS = false;
const ANIMATION_DURATION = 1000;

const STATES = {
  CHOOSING_ANSWERS: 0,
  HIDING_ANSWERS: 1,
  ENTERING_EMAIL: 2,
  SENDING: 3,
  SENT: 4,
  FINISHED: 100,
};

export default class ChoicesExercise extends ExerciseComponent {
  static propTypes = {
    "questions": propTypes.arrayOf(propTypes.shape({
      "content": propTypes.string,
      "answers": propTypes.arrayOf(propTypes.shape({
        "content": propTypes.string,
      })),
    })),
    "parameters": propTypes.shape({
      "answersLimit": propTypes.number,
      "shouldSendEmailWithComments": propTypes.bool,
    }),

    "onFinish": propTypes.func,
  };

  static fieldConstraints = {
    email: {
      email: {
        message: "^Podaj prawidłowy adres e-mail.",
      },
    },
    comment: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      current: STATES.CHOOSING_ANSWERS,
      selectedIds: [],

      form: {
        email: '',
        comment: '',
      },

      message: {
        content: '',
        type: 'error',
      },

      questionIndex: 0,
    };
  }

  render() {
    return (
      <AnimatedElement visible={!this.inState(STATES.FINISHED)} className="ChoicesExercise">
        <AnimatedElement visible={this.inState(STATES.CHOOSING_ANSWERS)}>
          { this.renderChoosingAnswers() }
        </AnimatedElement>
        <AnimatedElement visible={this.inStates([STATES.ENTERING_EMAIL, STATES.SENDING, STATES.SENT])}>
          { this.renderEnteringEmail() }
        </AnimatedElement>
      </AnimatedElement>
    );
  }

  renderChoosingAnswers = () => {
    const question = this.getCurrentQuestion();
    const {selectedIds} = this.state;

    let answers = question.answers.map((answer, index) => {
      const selected = selectedIds.includes(answer.id)
      return (
        <Card key={index} className={classNames({"selected": selected, "disabled": (!this.canChooseAnswer() && !selected)})}
          color={selected ? Card.COLORS.ACCENT : Card.COLORS.BRIGHT} onClick={this.answerSelected(answer)}
        >
          <ReactMarkdown source={answer.content}/>
        </Card>
      )
    });

    return [
      <InstructionCard key="instructions" visible={this.inState(STATES.CHOOSING_ANSWERS)}
        mainText={question.content}
      />,
      <div key="answers" className={classNames("answers", "scrollable")}>
        {answers}
      </div>,
      <AnimatedElement key="next-button" visible={this.inState(STATES.CHOOSING_ANSWERS)} animation={AnimatedElement.AnimationTypes.popOut}>
        <Button onClick={this.answersChosen} disabled={selectedIds.length === 0} big>
          Przejdź dalej
        </Button>
      </AnimatedElement>
  ]
  };

  getCurrentQuestion = () => {
    const {questionIndex} = this.state;
    const {questions} = this.props;

    if (!questions[questionIndex]) {
      console.warn("Trying to access question, that doesn't exist");
      return undefined;
    } else {
      return questions[questionIndex];
    }
  };

  answerSelected = (answer) => () => {
    this.setState((state) => {
      const id = answer.id;
      if (state.selectedIds.includes(id)) {
        state.selectedIds = _.pull(state.selectedIds, id);
      } else if (this.canChooseAnswer(state)) {
        state.selectedIds.push(id);
      }

      return {
        selectedIds: state.selectedIds,
      }
    })
  };

  canChooseAnswer = (state = this.state) => {
    const answersLimit = _.defaultTo(this.props.parameters["answersLimit"], DEFAULT_ANSWERS_LIMIT);
    return state.selectedIds.length < answersLimit;

  };

  answersChosen = () => {
    const SHOULD_SEND_EMAIL_WITH_COMMENTS = _.defaultTo(this.props.shouldSendEmailWithComments, DEFAULT_SHOULD_SEND_EMAIL_WITH_COMMENTS);
    let nextState, callback;
    if (SHOULD_SEND_EMAIL_WITH_COMMENTS) {
      nextState = STATES.ENTERING_EMAIL;
    } else {
      nextState = STATES.FINISHED;
      callback = this.onFinish;
    }

    this.setCurrentStateSequence([STATES.HIDING_ANSWERS, nextState], ANIMATION_DURATION, callback);
  };

  renderEnteringEmail = () => {
    return [
      <div key="email-info" className={classNames("email-info")}>
        <Card className="email-form" color={Card.COLORS.MAIN}>
          <div className={classNames("message", this.state.message.type, {"visible": this.state.message.content})}>
            <p>{ this.state.message.content }</p>
          </div>
          <label htmlFor="email">Podaj swój adres e-mail</label>
          <input name="email" type="email" value={this.state.form.email} onChange={this.emailFormChanged} />
          <label htmlFor="comment">Jeśli masz jakieś uwagi, które chcesz zapisać, wpisz je poniżej</label>
          <textarea name="comment" value={this.state.form.comment}  onChange={this.emailFormChanged}/>
        </Card>
      </div>,
      <AnimatedElement key="send-button" visible={this.inStates([STATES.ENTERING_EMAIL, STATES.SENDING, STATES.SENT])} animation={AnimatedElement.AnimationTypes.popOut}>
        <Button className={"send-button"} disabled={this.inStates([STATES.SENDING, STATES.SENT])} onClick={this.sendAnswers} big>
          { this.inState(STATES.ENTERING_EMAIL) && 'Wyślij' }
          { this.inStates([STATES.SENDING, STATES.SENT]) && <span><FontAwesomeIcon className="icon" icon={faSpinner} spin />  Wysyłanie</span> }
        </Button>
      </AnimatedElement>
    ];
  };

  emailFormChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((state) => {
      state.form[name] = value;

      return {
        form: state.form,
      }
    });
  };

  sendAnswers = () => {
    const errors = validate(this.state.form, ChoicesExercise.fieldConstraints);

    if (errors) {
      this.setState((state) => {
        let errorString = '';
        for (let errorList of Object.values(errors)) {
          errorString += errorList.join(' ');
        }

        state.message.content = errorString;

        return {
          formError: errorString,
        }
      });
    } else {
      this.setCurrentState(STATES.SENDING, () => {
        this.setState({
          message: {
            content: '',
            type: 'error',
          },
        }, this.sendAnswersToServer);
      });
    }
  };

  sendAnswersToServer = () => {
    // TODO: Przesyłanie na serwer
    this.delayedSetCurrentState(STATES.SENT, 2000, () => {
      this.setState({
        message: {
          content: "Wiadomość wysłana!",
          type: "success",
        }
      }, () => {
        this.delayedSetCurrentState(STATES.FINISHED, ANIMATION_DURATION, this.onFinish, ANIMATION_DURATION);
      })
    });
  };

  onFinish = () => {
    this.props.onFinish({
      other: {
        chosenAnswerIds: this.state.selectedIds,
      }
    });
  };
}