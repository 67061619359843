import StatsModule from "exercises/modules/StatsModule";
import TYPES from "./_types";

export default class ChosenAnswerStatsModule extends StatsModule {
  answerChosen(answer, options={}) {
    const {correct, chosenAnswerOther} = options;
    const reactionTimestamp = Date.now();

    super.answerChosen(answer);

    let data = {
      answerId: answer.id,
      questionId: this.currentQuestion.id,
      reactionTime: reactionTimestamp - this.timestamp,
    };

    if (correct !== undefined) {
      data['correct'] = correct;
    }

    if (chosenAnswerOther !== undefined) {
      data['other'] = chosenAnswerOther;
    }

    const newEvent = this.createEvent(TYPES.ANSWER_CHOSEN, data);

    this.addEvent(newEvent);
  }
}