import React from 'react';
import PropTypes from 'prop-types';

import ExerciseComponent from "base/ExerciseComponent";
import AnimatedElement from "components/AnimatedElement/AnimatedElement";
import Sounds from "lib/Sounds";
import InstructionCard from "components/InstructionCard/InstructionCard";
import Room from "./subcomponents/Room";
import processAnswers from "lib/answers";
import FeedbackCard from "components/FeedbackCard/FeedbackCard";

import "./DoorsExercise.scss";
import {DEFAULT_ANIMATION_SPEED} from "controllers/SprintController";
import ChosenAnswerStatsModule from "../../exercises/modules/stats/ChosenAnswerStatsModule";
import {defaultTo} from "lodash";
import {DEFAULT_STATES} from "base/ExerciseComponent";

import exerciseImg from "./img/exercise.jpg";
import exerciseVerticalImg from "./img/exercise-vertical.jpg";
import {INSTRUCTION_STEP_TYPES} from "base/subcomponents/ExerciseInstructions";

import {withTranslation} from "react-i18next";

const DEFAULT_TIME_LIMIT_S = 30;

const STATES = {
  ...DEFAULT_STATES,
  STARTING: 0,
  QUESTION_ANSWERING: 1,
  QUESTION_ANSWERED: 3,
  QUESTION_CHANGING: 4,
  ALL_QUESTIONS_ANSWERED: 5,
};

class DoorsExercise extends ExerciseComponent {
  static exerciseClass = "DoorsExercise";
  chosenAnswerIds = [];
  instruction;
  instructionsAvailable = true;

  initInstructions(props) {
    const {t} = props;

    this.instruction = t("game_instruction");
    this.instructions = {
      name: t("name"),
      steps: {
        [INSTRUCTION_STEP_TYPES.TARGET]: t("instruction_target"),
        [INSTRUCTION_STEP_TYPES.EXECUTION]: t("instruction_execution"),
        [INSTRUCTION_STEP_TYPES.CHOICES]: t("instruction_choices"),
        [INSTRUCTION_STEP_TYPES.POINTS]: t("instruction_points"),
      },
      imageHorizontal: exerciseImg,
      imageVertical: exerciseVerticalImg,
    };
  }

  static propTypes = {
    questions: PropTypes.array,
    parameters: PropTypes.shape({
      timePerQuestionSeconds: PropTypes.number,
    }),

    onFinish: PropTypes.func,
  };

  static maxPoints(questions, parameters) {
    let maxPoints = 0;
    for (let question of questions) {
      maxPoints += question.answers.length - 1
    }
    return maxPoints;
  }

  constructor(props) {
    super(props);

    const {parameters, questions} = props;

    this.state = {
      ...this.state,
      answers: [],
      chosenAnswer: undefined,

      isCorrectAnswerChosen: false,
      isIncorrectAnswerChosen: false,
      feedback: {
        successful: false,
        content: "",
      },

      questionIndex: 0,
      timeLimit: parameters.timePerQuestionSeconds ? parameters.timePerQuestionSeconds : DEFAULT_TIME_LIMIT_S,
    };

    this.timePerQuestionSeconds = defaultTo(parameters.timePerQuestionSeconds, DEFAULT_TIME_LIMIT_S);
    this.maxPoints = DoorsExercise.maxPoints(questions, parameters);
  }

  usedModules(questions, parameters) {
    return [
      new ChosenAnswerStatsModule({
        resetTimestampInStates: [STATES.QUESTION_ANSWERING],
      }, questions, parameters),
    ]
  }

  renderExercise(state, props) {
    const {questions} = props;
    const {
      answers, chosenAnswer, isCorrectAnswerChosen, isIncorrectAnswerChosen,
      feedback, questionIndex
    } = state;

    return <>
      <InstructionCard visible={this.inStates([STATES.QUESTION_ANSWERING, STATES.QUESTION_ANSWERED])}
        countType="Pytanie" countCurrent={questionIndex + 1} countMax={questions.length}
        mainText={questions[questionIndex].content}
      />

      <AnimatedElement className="room-container" visible={this.inStates([STATES.QUESTION_ANSWERING, STATES.QUESTION_ANSWERED, STATES.QUESTION_CHANGING])}>
        <Room answers={answers} chosenAnswer={chosenAnswer} questionIndex={questionIndex}
          lights={this.inStates([STATES.QUESTION_ANSWERING, STATES.QUESTION_ANSWERED])}
          onKeyChosenAction={this.onAnswerChosenAction}
          isDoorOpen={isCorrectAnswerChosen} isDoorShaking={isIncorrectAnswerChosen}
          onDoorClicked={this.onAnswerConfirmed}
        />
      </AnimatedElement>

      <FeedbackCard
        visible={this.inState(STATES.QUESTION_ANSWERED)}
        useDefaultFeedback={false}
        successful={feedback.successful}
        content={feedback.content}

      />
    </>
  }

  startGame = () => {
    this.prepareQuestion();
    this.setCurrentState(STATES.QUESTION_ANSWERING);
  };

  isClockRunning = () => {
    return this.inState(STATES.QUESTION_ANSWERING);
  };

  continue = () => {
    const {questionIndex} = this.state;
    const {questions} = this.props;

    if (questionIndex + 1 < questions.length) {
      setTimeout(() => {
        this.setState({
          questionIndex: questionIndex + 1,
        }, () => {
          this.prepareQuestion();
          this.setCurrentStateDelayed(STATES.QUESTION_ANSWERING, DEFAULT_ANIMATION_SPEED);
        });
      }, DEFAULT_ANIMATION_SPEED);
    } else {
      this.setCurrentState(STATES.FINISHING);
    }
  };

  finished = () => {
    const {onFinish} = this.props;
    const {points} = this.state;

    onFinish({
      points,
      other: {
        chosenAnswerIds: this.chosenAnswerIds,
      }
    })
  };

  timeRanOut = () => {
    this.setCurrentState(STATES.QUESTION_CHANGING);
    this.continue();
  };


  onAnswerChosenAction = (chosenAnswer) => () => {
    this.setState((state) => {
      const {answers} = state;
      if (state.chosenAnswer !== undefined) {
        answers[state.chosenAnswer.index].chosen = false;
      }
      answers[chosenAnswer.index].chosen = true;

      return {
        answers,
        chosenAnswer,
        isIncorrectAnswerChosen: false,
      }
    })
  };

  onAnswerConfirmed = () => {
    let hasFeedback;

    if (this.state.chosenAnswer) {
      super._answerChosen(this.state.chosenAnswer);

      this.setState((state) => {
        const {answers, chosenAnswer, points} = state;
        let pointChange = 0;

        this.chosenAnswerIds.push(chosenAnswer.id);

        const isCorrectAnswerChosen = chosenAnswer.correct;
        if (isCorrectAnswerChosen) {
          Sounds.success.play();
          for (let answer of answers) {
            if (answer.active)
              pointChange++;
          }
          pointChange--;
        } else {
          Sounds.error.play();
        }

        const isIncorrectAnswerChosen = !isCorrectAnswerChosen;
        hasFeedback = !!chosenAnswer.feedback;
        const feedback = {
          content: chosenAnswer.feedback,
          successful: isCorrectAnswerChosen,
        };

        answers[chosenAnswer.index] = {
          ...answers[chosenAnswer.index],
          active: false,
          chosen: false,
        };

        return {
          points: points + pointChange,
          answers,
          chosenAnswer: undefined,
          isCorrectAnswerChosen,
          isIncorrectAnswerChosen,
          feedback,
        }
      }, () => {
        const {isCorrectAnswerChosen} = this.state;
        const delay = hasFeedback ? 5000 : (isCorrectAnswerChosen ? DEFAULT_ANIMATION_SPEED : 0);
        if (isCorrectAnswerChosen) {
          this.setCurrentStateSequence([STATES.QUESTION_ANSWERED, STATES.QUESTION_CHANGING], delay, this.continue);
        } else {
          this.setCurrentStateSequence([STATES.QUESTION_ANSWERED, STATES.QUESTION_ANSWERING], delay);
        }
      })
    }
  };

  prepareQuestion = () => {
    const {questions} = this.props;
    const {questionIndex} = this.state;

    const currentAnswers = questions[questionIndex].answers;
    super._questionAppeared(questions[questionIndex]);

    this.setState({
      chosenAnswer: undefined,
      isCorrectAnswerChosen: false,
      isIncorrectAnswerChosen: false,
      answers: processAnswers(currentAnswers, DoorsExercise.prepareAnswer)
    })
  };

  static prepareAnswer(answer, index) {
    return {
      id: answer["id"],
      index: index,

      content: answer["content"],
      correct: answer["correct"],
      feedback: answer["parameters"]["feedback"],

      active: true,
      chosen: false,
    }
  }
}

export default withTranslation(["exercises/doors", "common"])(DoorsExercise);