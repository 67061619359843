import React from 'react';
import classNames from 'classnames';

import './ElevatorCard.scss'
import Card from "components/Card/Card";
import Button from "components/Button/Button";

import { DOOR_ANIMATION_MS } from './ElevatorDoors';

export default function ElevatorCard({content, heightInRem, open, bottomDistanceInRem, onAnswerChosen})  {
  const doorStyle = {
    transitionDuration: `${DOOR_ANIMATION_MS}ms`,
  };

  return (
    <Card className={classNames("ElevatorCard", {open})}
      style={{height: `${heightInRem}rem`, marginBottom: `${bottomDistanceInRem}rem`}}
    >
      <div className="left-door" style={doorStyle} />
      <div className="right-door" style={doorStyle} />
      <div className="content">
        {content}
      </div>
      <Button onClick={onAnswerChosen}>
        Wysiadam
      </Button>
      {/*<div className="line" style={{height: `${bottomDistanceInRem}rem`}} />*/}
    </Card>
  )
}