import React  from 'react';

import './css/SprintBar.css';
import {useTranslation} from "react-i18next";

function SprintBar({
  points = 0,
  maxPoints = 100,
  level = 1,
  maxLevels = 1,
  parameters = {},
  width,

  // helpClick,
  // skipClick,
}) {
  const {t} = useTranslation("common");

  const {hidePoints=false} = parameters;

  return (
    <div className="SprintBar">
      <div className="logo">
        <img src="/images/logo-blue.png" alt="Sprintech Learning logo" />
      </div>
      <div className="container" style={{'width': width}}>
        <div className="pure-u-2-5 left">{!hidePoints && <>{t("sprintScore")}: <strong>{points} / {maxPoints}</strong></>}</div>
        <div className="pure-u-1-5 center"><strong>{level} / {maxLevels}</strong></div>
        <div className="pure-u-2-5 right">{/*<Card classes="help-button" onClick={this.props.skipClick}><i className="fas fa-times-circle" /> Zakończ</Card><Card classes="help-button" onClick={this.props.helpClick}><i className="fas fa-question" /> Pomoc</Card>*/}</div>
      </div>
    </div>
  );
}

export default SprintBar;
