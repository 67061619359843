import React, {Component} from 'react';
import PropTypes from 'prop-types';

import PlayArea from "lib/PlayArea";

import ElevatorCard from "./ElevatorCard";
import './ElevatorDoors.scss';

const FLOOR_DURATION_MS = 500;
export const DOOR_ANIMATION_MS = 500;

export default class ElevatorDoors extends Component {
  static propTypes = {
    answers: PropTypes.array,
    currentFloor: PropTypes.number,
    onAnswerChosenAction: PropTypes.func,
  };

  openDoorTimeout;

  constructor(props) {
    super(props);

    this.state = {
      floor: undefined,
      transitionDuration: FLOOR_DURATION_MS,

      doorOpen: false,
    };
  }

  componentDidMount() {
    const {currentFloor} = this.props;
    this.updateFloorAndTransition(-1, currentFloor);
  }

  componentDidUpdate(prevProps, prevState) {
    let {currentFloor} = this.props;
    let prevFloor = prevProps.currentFloor;

    if (prevFloor === undefined) {
      prevFloor = -1;
    }
    if (currentFloor === undefined) {
      currentFloor = -1;
    }

    if (prevFloor !== currentFloor) {
      this.updateFloorAndTransition(prevFloor, currentFloor);
    }
  }

  updateFloorAndTransition(prevFloor, currentFloor) {
    const duration = Math.abs(currentFloor-prevFloor) * FLOOR_DURATION_MS;

    this.setState({
      floor: currentFloor,
      transitionDuration: duration,

      doorOpen: false,
    });

    clearTimeout(this.openDoorTimeout);
    this.openDoorTimeout = setTimeout(this.openDoor, duration + DOOR_ANIMATION_MS);
  }

  openDoor = () => {
    this.setState({
      doorOpen: true,
    })
  };

  render() {
    const {answers, onAnswerChosenAction} = this.props;
    const {floor, transitionDuration, doorOpen} = this.state;
    const doorHeight = this.calculateDoorHeight();
    const answersDistance = this.calculateAnswersDistance();
    const elevatorPosition = this.getFloorNumber() * this.calculateFloorHeight();
    const doorsPosition = (answers.length - 1) * this.calculateFloorHeight();
    const elevatorStyle = {
      transform: `translateY(${elevatorPosition}rem)`,
      transitionDuration: `${transitionDuration}ms`,
      transitionDelay: `${DOOR_ANIMATION_MS}ms`,
      marginTop: `-${doorsPosition}rem`
    };

    const answerElements = answers.map((answer, index) => (
      <ElevatorCard key={index} open={doorOpen && index === floor}
        content={answer.content} heightInRem={doorHeight} bottomDistanceInRem={answersDistance}
        onAnswerChosen={onAnswerChosenAction(index)}
      />
    )).reverse();

    return <div className="ElevatorDoors" style={elevatorStyle}>
      {answerElements}
      <div className="shaft" />
    </div>
  }

  calculateDoorHeight = () => {
    return PlayArea.isVertical() ? 40 : 30;
  };

  calculateAnswersDistance = () => {
    return PlayArea.heightInEms();
  };

  calculateFloorHeight = () => {
    return this.calculateDoorHeight() + this.calculateAnswersDistance();
  };

  getFloorNumber = () => {
    const {floor} = this.state;

    return floor !== undefined ? floor : -1;
  };
}