import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';
import _ from 'underscore';

import { QuestionAnswerCard } from "components/AnswerCard";
import Card from "components/Card";

import './css/OldQuestionsExercise.css'
import PointsBar from "components/PointsBar";

const ANIMATION_SPEED = 1000;

export default class OldQuestionsExercise extends Component {

  static propTypes = {
    answers: PropTypes.array,

    goNextAction: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: true,

      gameFinished: false,
      points: 0,
      playing: true,

      timeout: null,

      answers: _.clone(props.answers),
      currentQuestion: 0,
      currentPoints: 4,

      changed: 0,
    };

    for (let questionIndex in this.state.answers) {
      if (this.state.answers.hasOwnProperty(questionIndex)) {
        this.state.answers[questionIndex] = _.clone(this.state.answers[questionIndex]);
        let questionInfo = this.state.answers[questionIndex];
        questionInfo.question = {
          content: questionInfo.question,
          visible: true,
        };

        for (let answerIndex in questionInfo.answers) {
          if (questionInfo.answers.hasOwnProperty(answerIndex)) {
            let answer = questionInfo.answers[answerIndex];
            answer.index = answerIndex;
            answer.questionIndex = questionIndex;

            answer.timeout = null;
            answer.visible = true;
            answer.side = 'front';
          }
        }
      }
    }

    this.goNext = this.goNext.bind(this);

    this._goNext = this._goNext.bind(this);
  }

  answerChosen = (answer) => {
    if (!this.state.playing) {
      return;
    }

    if (answer.correct) {
      this.setState((prevState) => {
        let answerInState = prevState.answers[prevState.currentQuestion].answers[answer.index];
        answerInState.side = 'back';

        let timeout = setTimeout(this.prepareForNextQuestion, 1000);

        return {
          timeout: timeout,
          playing: false,
          points: prevState.points + prevState.currentPoints,
          answers: prevState.answers,
        }
      });

      new Audio('/sounds/success.mp3').play();
    } else {
      this.setState((prevState) => {
        let answerInState = prevState.answers[prevState.currentQuestion].answers[answer.index];
        answerInState.timeout = setTimeout(this._hideAnswer.bind(this, answer), 2000);
        answerInState.side = 'back';

        return {
          currentPoints: prevState.currentPoints - 1,
          answers: prevState.answers,
        }
      });

      new Audio('/sounds/error.mp3').play();

    }
  };

  _hideAnswer = (answer) => {
    this.setState((prevState) => {
      let stateAnswer = prevState.answers[answer.questionIndex].answers[answer.index];
      stateAnswer.visible = false;
      stateAnswer.timeout = null;

      return {
        answers: prevState.answers,
      }
    })
  };

  prepareForNextQuestion = () => {
    this.setState((prevState) => {
      let gameFinished = false;
      let timeout = null;

      if (prevState.currentQuestion + 1 < prevState.answers.length) {
        let answerInfo = prevState.answers[prevState.currentQuestion];

        answerInfo.question.visible = false;
        for (let answer of answerInfo.answers) {
          if (answer.timeout) {
            clearTimeout(answer.timeout);
            answer.timeout = null;
          }
          answer.visible = false;
          answer.side = 'front';
        }

        timeout = setTimeout(this.showNextQuestion, 1000);
      } else {
        gameFinished = true;
      }

      return {
        finished: gameFinished,
        timeout: timeout,
        answers: prevState.answers,
      }
    });
  };

  showNextQuestion = () => {
    this.setState((prevState) => {
      return {
        playing: true,
        currentQuestion: prevState.currentQuestion + 1,
        currentPoints: 4, // TODO: You could get number of answers for given question
      };
    });
  };

  goNext() {
    new Audio('/sounds/click.mp3').play();
    this.setState({
      visible: false,
    });
    setTimeout(this._goNext, ANIMATION_SPEED);
  }

  _goNext() {
    this.props.goNextAction(this.state.points);
  }

  render() {
    let answersInfo = this.state.answers[this.state.currentQuestion];
    let question = (
      <CSSTransitionGroup
        transitionName='example'
        transitionEnter={true}
        transitionEnterTimeout={ANIMATION_SPEED}
        transitionLeave={true}
        transitionLeaveTimeout={ANIMATION_SPEED}
        transitionAppear={true}
        transitionAppearTimeout={ANIMATION_SPEED}
        key={answersInfo.question.content}
      >
      {answersInfo.question.visible &&
      <Card>
        <p>
          {answersInfo.question.content}
        </p>
      </Card>
      }
      </CSSTransitionGroup>
    );

    let answers = answersInfo.answers.map((answer, i) => {
      let classes = (i === answersInfo.answers.length - 1 ? 'last' : '');

      return (
        <QuestionAnswerCard
          classes={classes}

          answer={answer}

          type="text"
          side={answer.side}
          index={i}
          columns={1}

          clickCallback={this.answerChosen}

          key={i}
        />
      )
    });

    return (
      <CSSTransitionGroup
        transitionName="example"
        transitionAppear={true}
        transitionAppearTimeout={ANIMATION_SPEED}
        transitionEnter={true}
        transitionEnterTimeout={ANIMATION_SPEED}
        transitionLeave={true}
        transitionLeaveTimeout={ANIMATION_SPEED}>
        {this.state.visible &&
        <div className="QuestionsExercise">
          <div className="pure-g points-bar-container">
            <div className="pure-u-1-1">
              <PointsBar
                points={this.state.points} maxPoints={20}
                instruction={this.props.instruction}
                hideClock={true}/>
            </div>
          </div>
          <div className="pure-g answers-container">
            <div className="pure-u-1-3 question">
              <div className="vertical-helper" />
              {question}
            </div>
            <div className="pure-u-2-3 answers">
              {answers}
            </div>
          </div>
          <CSSTransitionGroup
            transitionName="example"
            transitionEnter={true}
            transitionEnterTimeout={ANIMATION_SPEED}
            transitionLeave={true}
            transitionLeaveTimeout={ANIMATION_SPEED}>
            {this.state.gameFinished &&
            <div className="pure-g buttons-container">
              <div className="pure-u-1-1 center">
                <Card classes="next-button" onClick={this.goNext}>
                  <p>Przejdź dalej</p>
                </Card>
              </div>
            </div>
            }
          </CSSTransitionGroup>
        </div>
        }
      </CSSTransitionGroup>
    );
  }
}
