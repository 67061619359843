import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AnimatedElement from "../../components/AnimatedElement/AnimatedElement";
import InstructionCard from "../../components/InstructionCard/InstructionCard";
import ExerciseComponent, {DEFAULT_STATES} from "../../base/ExerciseComponent";
import Button from "../../components/Button/Button";
import Sounds from "../../lib/Sounds";

import RevealExerciseCard from "./subcomponents/RevealExerciseCard";
import './RevealExercise.scss';
import {INSTRUCTION_STEP_TYPES} from "../../base/subcomponents";
import exerciseImg from "./img/exercise.jpg";
import exerciseVerticalImg from "./img/exercise-vertical.jpg";
import ChosenAnswerStatsModule from "../../exercises/modules/stats/ChosenAnswerStatsModule";
import {withTranslation} from "react-i18next";
import {defaultTo} from "lodash";

const STATES = {
  ...DEFAULT_STATES,
  QUESTION_APPEARING: 1,
  QUESTION_ANSWERING: 2,
  QUESTION_ANSWERING_UNBLURRED: 3,
  QUESTION_ANSWERED: 4,
  QUESTION_HIDING: 5,
  QUESTION_HIDDEN: 6,
  ALL_QUESTIONS_ANSWERED: 7,
  FINISHED: 8,
};

const DEFAULT_TIME_LIMIT = 120;
const ANIMATION_SPEED = 1000;

const POINTS_FOR_CORRECT_ANSWER = 2;
const POINTS_FOR_INCORRECT_ANSWER = -2;

class RevealExercise extends ExerciseComponent {
  chosenAnswerIds = [];
  negativePointsAllowed = true;
  instruction = "";
  instructionsAvailable = true;

  static propTypes = {
    questions: PropTypes.array,
    parameters: PropTypes.shape({
      timePerQuestionSeconds: PropTypes.number,
      shouldBlurAnswers: PropTypes.bool,
    }),

    onFinish: PropTypes.func,
  };

  static maxPoints(questions) {
    let maxPoints = 0;

    for (let question of questions) {
      for (let answer of question.answers) {
        if (answer.correct) {
          maxPoints += POINTS_FOR_CORRECT_ANSWER
        }
      }
    }

    return maxPoints;
  }

  static countCorrectAnswersByQuestion(questions) {
    let correctAnswersByQuestion = [];
    let currentIndex = 0;

    for (let question of questions) {
      correctAnswersByQuestion[currentIndex] = 0;
      for (let answer of question.answers) {
        if (answer.correct) {
          correctAnswersByQuestion[currentIndex] += 1;
        }
      }
      currentIndex++;
    }

    return correctAnswersByQuestion;
  }

  initInstructions (props) {
    const {t, parameters} = props;
    const shouldBlurAnswers = defaultTo(parameters.shouldBlurAnswers, true);

    this.instruction = (shouldBlurAnswers ? t("game_instruction_blurred") : t("game_instruction"));
    this.instructions = {
      name: t("name"),
      steps: {
        [INSTRUCTION_STEP_TYPES.TARGET]: t("instruction_target"),
        [INSTRUCTION_STEP_TYPES.EXECUTION]: shouldBlurAnswers ? t("instruction_execution_blurred") : t("instruction_execution"),
        [INSTRUCTION_STEP_TYPES.CHOICES]: t("instruction_choices"),
        [INSTRUCTION_STEP_TYPES.POINTS]: t("instruction_points"),
      },
      imageHorizontal: exerciseImg,
      imageVertical: exerciseVerticalImg,
    };
  }

  constructor(props) {
    super(props);


    let {timePerQuestionSeconds, shouldBlurAnswers} = props.parameters;
    if (shouldBlurAnswers === undefined) {
      shouldBlurAnswers = true;
    }

    this.state = {
      ...this.state,

      correctAnswersLeftByQuestion: RevealExercise.countCorrectAnswersByQuestion(props.questions),
      lastClickedAnswerIndex: undefined,
    };

    ExerciseComponent.shuffleAnswers(this.state);
    ExerciseComponent.processAnswers(this.state, {
      isActive: true,
      wasActivated: false,
      isBlurred: shouldBlurAnswers,
    });

    this.timePerQuestionSeconds = timePerQuestionSeconds || DEFAULT_TIME_LIMIT;
    this.maxPoints = RevealExercise.maxPoints(props.questions);
  }

  usedModules(questions, parameters) {
    return [
      new ChosenAnswerStatsModule({
        resetTimestampOnEventOccurrence: true,
        resetTimestampInStates: [STATES.QUESTION_ANSWERING, STATES.QUESTION_ANSWERING_UNBLURRED],
      }, questions, parameters),
    ]
  }

  renderExercise() {
    const {questions, questionIndex} = this.state;
    const {t} = this.props;

    return (
      <AnimatedElement className="RevealExercise" visible={!this.inState(STATES.INSTRUCTIONS_SHOWING, STATES.STARTING, STATES.FINISHING, STATES.FINISHED)}>
        <InstructionCard visible={this.inStates([STATES.QUESTION_APPEARING, STATES.QUESTION_ANSWERING_UNBLURRED, STATES.QUESTION_ANSWERING, STATES.QUESTION_ANSWERED])}
          countType={t("common:question")} countCurrent={questionIndex + 1} countMax={this.state.questions.length}
          mainText={questions[questionIndex].content}
        />
        <AnimatedElement className={classNames("answers", "scrollable", {"inactive": !this.inStates([STATES.QUESTION_ANSWERING_UNBLURRED, STATES.QUESTION_ANSWERING])})}
          visible={this.inStates([STATES.QUESTION_APPEARING, STATES.QUESTION_ANSWERING_UNBLURRED, STATES.QUESTION_ANSWERING, STATES.QUESTION_ANSWERED])}>
          { this.renderAnswers() }
        </AnimatedElement>
        <AnimatedElement fullSize visible={this.inStates(STATES.QUESTION_ANSWERED)} animation={AnimatedElement.AnimationTypes.popOut} appearDelayMs={2000}>
          <Button onClick={this.continueGame} big>
            {t("common:continue")}
          </Button>
        </AnimatedElement>
      </AnimatedElement>
    );
  }

  isClockRunning = () => {
    return this.inStates([STATES.QUESTION_ANSWERING_UNBLURRED, STATES.QUESTION_ANSWERING]);
  };

  timeRanOut = () => {
    this.setCurrentState(STATES.QUESTION_ANSWERED);
  };

  startGame = () => {
    this.showQuestion();
  };

  showQuestion = () => {
    const {questions, questionIndex} = this.state;
    super._questionAppeared(questions[questionIndex]);

    this.setCurrentStateSequence([STATES.QUESTION_APPEARING, STATES.QUESTION_ANSWERING], ANIMATION_SPEED);
  };

  renderAnswers = () => {
    const {questions, questionIndex} = this.state;

    return questions[questionIndex].answers.map((answer, index) => {
      return (
        <RevealExerciseCard key={index} index={index} onClick={this.answerClicked}
          content={answer.content}
          isBlurred={this.inState([STATES.QUESTION_APPEARING, STATES.QUESTION_ANSWERING_UNBLURRED, STATES.QUESTION_ANSWERING]) && answer.isBlurred}
          isCorrect={answer.correct}
          isActive={this.inStates([STATES.QUESTION_ANSWERING_UNBLURRED, STATES.QUESTION_ANSWERING]) && answer.isActive}
          wasActivated={answer.wasActivated}
          isShowingFeedback={this.inState([STATES.QUESTION_ANSWERED, STATES.QUESTION_HIDING])
          || (this.inStates([STATES.QUESTION_ANSWERING_UNBLURRED, STATES.QUESTION_ANSWERING]) && !answer.isActive)}
        />
      );
    });
  };

  answerClicked = (index) => {
    let unblurred = false;

    if (this.inStates([STATES.QUESTION_ANSWERING_UNBLURRED, STATES.QUESTION_ANSWERING])) {
      this.setState((state) => {
        let {points, questions, questionIndex, lastClickedAnswerIndex, correctAnswersLeftByQuestion} = state;
        let answers = questions[questionIndex].answers;
        let clickedAnswer = answers[index];

        if (lastClickedAnswerIndex !== undefined && lastClickedAnswerIndex !== index) {
          answers[lastClickedAnswerIndex].isBlurred = true;
        }
        lastClickedAnswerIndex = index;

        if (clickedAnswer.isBlurred) {
          unblurred = true;
          clickedAnswer.isBlurred = false;
          Sounds.click.play();
        } else {
          super._answerChosen(clickedAnswer);

          clickedAnswer.isActive = false;
          clickedAnswer.wasActivated = true;
          lastClickedAnswerIndex = undefined;

          this.chosenAnswerIds.push(clickedAnswer.id);

          if (clickedAnswer.correct) {
            points += POINTS_FOR_CORRECT_ANSWER;
            Sounds.success.play();

            correctAnswersLeftByQuestion[questionIndex] -= 1;
          } else {
            points = POINTS_FOR_INCORRECT_ANSWER + points;
            Sounds.error.play();
          }
        }

        return {
          correctAnswersLeftByQuestion,
          lastClickedAnswerIndex,
          questions,
          points,
        }
      }, () => {
        const {correctAnswersLeftByQuestion, questionIndex} = this.state;

        if (correctAnswersLeftByQuestion[questionIndex] === 0) {
          this.setCurrentState(STATES.QUESTION_ANSWERED);
        } else if (unblurred) {
          this.setCurrentStateSequence([STATES.QUESTION_ANSWERING_UNBLURRED, STATES.QUESTION_ANSWERING], 1);
        }
      })
    }
  };

  continueGame = () => {
    let {questionIndex, questions} = this.state;

    if (questionIndex + 1 < questions.length) {
      this.setCurrentStateSequence([STATES.QUESTION_HIDING, STATES.QUESTION_HIDDEN], ANIMATION_SPEED,
        this.nextQuestion);
    } else {
      this.setCurrentStateSequence([STATES.QUESTION_HIDING, STATES.QUESTION_HIDDEN, STATES.FINISHING],ANIMATION_SPEED);
    }
  };

  gameFinished = () => {
    this.finish(true, {
      chosenAnswerIds: this.chosenAnswerIds,
    })
  };

  nextQuestion = () => {
    if (!this.inState(STATES.QUESTION_HIDDEN)) {
      console.warn("nextQuestion should only be called in state QUESTION_HIDDEN");
    }

    this.setState((state) => {
      let {questionIndex} = state;
      questionIndex++;

      return {
        questionIndex,
        lastClickedAnswerIndex: undefined,
      }
    }, () => {
      this.changeClockId();
      this.showQuestion();
    });
  };
}

export default withTranslation(["exercises/reveal", "common"])(RevealExercise);