import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import "./config";
import './i18n';

import App from './App';

// import 'babel-polyfill';
// import registerServiceWorker from './registerServiceWorker';

import WebFont from 'webfontloader';

ReactDOM.render(
  <Suspense fallback={<div className="loading" />}>
    <App />
  </Suspense>
  ,document.getElementById('root'));

// registerServiceWorker();

WebFont.load({
  google: {
    families: ['Lato:400,700:latin,latin-ext', 'sans-serif']
  },
  custom: {
    families: ['Font Awesome 5 Icons:400,900', 'Font Awesome 5 Brands:400'],
    urls: ['//use.fontawesome.com/releases/v5.1.0/css/all.css']
  },
});
