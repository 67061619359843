import React from 'react';
import classNames from 'classnames';

import ExerciseComponent from "base/ExerciseComponent";
import AnimatedElement from "components/AnimatedElement/AnimatedElement";
import InstructionCard from "components/InstructionCard/InstructionCard";
import Button from "components/Button/Button";

import './OpenQuestionExercise.scss';

const STATE = {
  STARTED: 0,
  FINISHED: 100,
};

export default class OpenQuestionExercise extends ExerciseComponent {

  constructor(props) {
    super(props);
    const question = props.questions[0];

    this.state = {
      ...this.state,
      question,
      answers: question.answers,
      selectedId: undefined,

      value: '',
    };

    if (question.answers.length === 1) {
      this.state.selectedId = question.answers[0].id;
    }
  }

  render() {
    const {question, answers, selectedId, value} = this.state;

    const answerComponents = answers.map((answer, index) => {
      const isSelected = (answer.id === selectedId);

      return (
        <Button key={index} onClick={this.selectAction(answer.id)}
          className={classNames({"selected": isSelected})} disabled={isSelected}
        >
          {answer.content}
        </Button>
      )
    });

    return (
      <AnimatedElement visible={!this.inState(STATE.FINISHED)} className="OpenQuestionExercise">
        <InstructionCard mainText={question.content} />
        <div className="answers-container">
          {answers.length > 1 && answerComponents}
        </div>
        <textarea value={value} onChange={this.textChanged} />
        <Button big onClick={this.finish} disabled={!this.canSend()}>Prześlij</Button>
      </AnimatedElement>
    )
  }

  textChanged = (event) => {
    this.setState({
      value: event.target.value,
    });
  };

  selectAction = (id) => () => {
    this.setState({
      selectedId: id,
    })
  };

  canSend = () => {
    return this.state.value.length > 10 && this.state.selectedId
  };

  finish = () => {
    this.setCurrentState(STATE.FINISHED, this.onFinish, 1000);
  };

  onFinish = () => {
    let chosenAnswerData = {
      id: this.state.selectedId,
      data: {
        answer: this.state.value,
      },
    };

    this.props.onFinish({
      other: {
        chosenAnswerData,
      }
    });
  }
}