import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';
import _ from 'underscore';

import AnswerCard from "components/AnswerCard";
import Card from "components/Card";

import './css/MemoryMatchExercise.css'
import PointsBar from "components/PointsBar";
import OverlayMessage from "components/OverlayMessage";

const ANIMATION_SPEED = 1000;

const TUTORIAL_MESSAGES = [
  'W tym ćwiczeniu musisz znajdować pary sformułowań opisujących to samo zjawisko. Kliknij na podświetloną kartę.',
  'Teraz, kliknij na drugą podświetloną kartę.',
  'Świetnie! Jak widzisz oba sformułowania opisują to samo, ale robią to w inny sposób. Jedna z nich to suchy fakt, a druga to mówienie obrazowe. Zdobywasz punkt. Kliknij w kolejną podświetloną kartę.',
  'A teraz w następną.',
  'Te sformułowania ewidentnie opisują inne zjawiska. Za tak odkrytą parę nie zdobywasz punktu. A teraz spróbuj swoich sił samodzielnie. Powodzenia! :)',
];

// const TUTORIAL_MESSAGES = [
//   'W tym ćwiczeniu musisz znajdować pary obrazków powiązanych ze sobą. Kliknij na podświetloną kartę.',
//   'Teraz, kliknij na drugą podświetloną kartę.',
//   'Świetnie! Jak widzisz oba obrazki pasują do siebie. Zdobywasz punkt. Kliknij w kolejną podświetloną kartę.',
//   'A teraz w następną.',
//   'Te obrazki ewidentnie nie mają ze sobą nic wspólnego. Za tak odkrytą parę nie zdobywasz punktu. A teraz spróbuj swoich sił samodzielnie. Powodzenia! :)',
// ];

export default class MemoryMatchExercise extends Component {

  static propTypes = {
    instruction: PropTypes.string,
    answers: PropTypes.arrayOf(Object),
    tutorial: PropTypes.bool,

    goNextAction: PropTypes.func,
  };

  static defaultProps = {
    tutorial: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: true,

      answers: [],
      lastKey: null,
      lastAnswer: null,

      clockRunning: false,
      gameFinished: false,
      timeRanOut: false,
      points: 0,
      playing: true,

      tutorial: {
        active: props.tutorial,

        messages: TUTORIAL_MESSAGES,
        currentStep: 0,
      },

      changed: 0,
    };

    let tutorialCorrectId = -1;
    let tutorialIncorrectId = -1;
    let tutorialIncorrectSet = false;
    for (let answerInfo of props.answers) {
        let answer = {
          id: answerInfo.id,
          image: answerInfo.image,
          text: answerInfo.text,
          visible: true,
          success: false,
          side: 'front',
        };

        if (tutorialCorrectId < 0) {
          tutorialCorrectId = answerInfo.id;
          answer.tutorialSteps = [0, 2];
        } else if (tutorialCorrectId === answerInfo.id) {
          answer.tutorialSteps = [1, 2];
        } else if (tutorialIncorrectId < 0) {
          tutorialIncorrectId = answerInfo.id;
          answer.tutorialSteps = [2, 4];
        } else if (!tutorialIncorrectSet && tutorialIncorrectId !== answerInfo.id) {
          answer.tutorialSteps = [3, 4];
          tutorialIncorrectSet = true;
        } else {
          answer.tutorialSteps = [];
        }

        this.state.answers.push(answer);
    }

    this.state.answers = _.shuffle(this.state.answers);

    this.answerChosen = this.answerChosen.bind(this);
    this.goNext = this.goNext.bind(this);
    this._goNext = this._goNext.bind(this);
  }


  answerChosen(answer, key, event) {
    if (!this.state.playing) {
      return;
    }

    let lastAnswer = this.state.lastAnswer;
    let lastKey = this.state.lastKey;
    let points = this.state.points;
    let playing = true;
    let flip = false;
    let gameFinished = false;
    let clockRunning = true;

    if (lastAnswer) {
      if (answer.id === lastAnswer.id) {
        new Audio('/sounds/success.mp3').play();
        points += 2;

        if (points === this.state.answers.length) {
          gameFinished = true;
          clockRunning = false;
        }
      } else {
        new Audio('/sounds/error.mp3').play();
        if (!this.state.tutorial.active) {
          flip = true;
        }
      }
      lastAnswer = null;
    } else {
      new Audio('/sounds/click.mp3').play();
      lastAnswer = answer;
      lastKey = key;
    }

    this.setState((prevState) => {
      if (!lastAnswer && !flip) {
        // We got correct answer!
        setTimeout(this.setSuccess.bind(this, [key, lastKey]), ANIMATION_SPEED);
      }

      prevState.answers[key].side = 'back';
      return {
        finished: gameFinished,
        playing: playing,
        lastKey: lastKey,
        lastAnswer: lastAnswer,
        points: points,
        answers: prevState.answers,
        clockRunning: clockRunning,
      }
    });

    if (flip) {
      setTimeout(() => {
        this.setState((prevState) => {
          prevState.answers[key].side = 'front';
          prevState.answers[lastKey].side = 'front';
          return {
            playing: true,
            answers: prevState.answers,
          }
        });
      }, 2000);
    }

    if (this.state.tutorial.active) {
      this.setState((prevState) => {
        prevState.tutorial.currentStep++;

        let playing = true;
        if (prevState.tutorial.currentStep === prevState.tutorial.messages.length - 1) {
          playing = false;
        }

        return {
          playing: playing,
          finished: !playing,
          tutorial: prevState.tutorial,
        }
      });
    }
  }

  setSuccess = (keys) => {
    this.setState((prevState) => {
      for (let key of keys) {
        prevState.answers[key].success = true;
      }

      return {
        answers: prevState.answers,
      }
    });
  };

  timeRanOut = () => {
    this.setState({
      playing: false,
      finished: true,
      timeRanOut: true,
      clockRunning: false,
    });
  };

  goNext() {
    new Audio('/sounds/click.mp3').play();
    this.setState({
      visible: false,
    });
    setTimeout(this._goNext, ANIMATION_SPEED);
  }

  _goNext() {
    let points = 0;
    if (!this.state.tutorial.active) {
      points = this.state.points;
    }
    this.props.goNextAction(points);
  }

  render() {
    let columns = 4;
    let answers = this.state.answers;
    let itemsLength = answers.length;
    let answerRows = [];
    let rowCount = Math.floor(itemsLength / columns);

    for (let i = 0; i < rowCount; i++) {
      let answersForRow = this.state.answers.slice(i * columns, (i + 1) * columns).map((answer, j) => {
        let index = i * columns + j;
        let style = {
          backgroundImage: "url('/images/memory/" + answer.image + ".jpg')"
        };
        let front = (
          <div className="image" />
        );

        let back;
        if (answer.text) {
          back = (
            <div className={answer.success ? 'success' : ''} style={style}>
              <p>{answer.text}</p>
            </div>
          );
        } else {
          back = (
            <div className={answer.success ? 'success' : ''} style={style} />
          );
        }

        let classes = 'MemoryAnswerCard';

        if (this.state.tutorial.active) {
          let answerTutorialStep = _.indexOf(answer.tutorialSteps, this.state.tutorial.currentStep);
          if (answerTutorialStep > -1) {
            classes += ' tutorial-show';
          }
          if (answerTutorialStep === 0) {
            classes += ' tutorial-highlight';
          }
        }

        return [
          <AnswerCard
            classes={classes}

            answer={answer}

            side={answer.side}
            index={index}
            columns={columns}

            clickCallback={this.answerChosen}

            front={front}
            back={back}

            key={index}
          />,
          ' ',
        ]
      });
      let answersRow = (
        <div className={'answers-row pure-u-1-1' + (i === rowCount - 1 ? ' last' : '')} key={i}>
          {answersForRow}
        </div>
      );
      answerRows.push(answersRow);
    }

    return (
      <CSSTransitionGroup
        transitionName="example"
        transitionAppear={true}
        transitionAppearTimeout={ANIMATION_SPEED}
        transitionEnter={true}
        transitionEnterTimeout={ANIMATION_SPEED}
        transitionLeave={true}
        transitionLeaveTimeout={ANIMATION_SPEED}>
        {this.state.visible &&
        <div className="MemoryMatchExercise">
          <OverlayMessage
            visible={this.state.tutorial.active}
            messages={this.state.tutorial.messages} messageId={this.state.tutorial.currentStep} />
          <div className="pure-g points-bar-container">
            <div className="pure-u-1-1">
              <PointsBar
                points={this.state.points} maxPoints={this.state.answers.length}
                timeLimit={90} clockRunning={!this.state.tutorial.active && this.state.clockRunning} onTimeRanOut={this.timeRanOut}
                instruction={this.props.instruction}
              />
            </div>
          </div>
          <div className={'pure-g answers-container' + (this.state.timeRanOut ? ' game-finished' : '')}>
            <CSSTransitionGroup
              transitionName="example"
              transitionAppear={true}
              transitionAppearTimeout={ANIMATION_SPEED}
              transitionEnter={true}
              transitionEnterTimeout={ANIMATION_SPEED}
              transitionLeave={true}
              transitionLeaveTimeout={ANIMATION_SPEED}>
            {this.state.timeRanOut &&
              <Card classes='timeFinished pure-u-1-1'>
                <h1>Koniec czasu!</h1>
              </Card>
            }
            </CSSTransitionGroup>
            { answerRows }
          </div>
          <CSSTransitionGroup
            transitionName="example"
            transitionEnter={true}
            transitionEnterTimeout={ANIMATION_SPEED}
            transitionLeave={true}
            transitionLeaveTimeout={ANIMATION_SPEED}>
            {this.state.gameFinished &&
            <div className="pure-g buttons-container">
              <div className="pure-u-1-1 center">
                <Card classes="next-button" onClick={this.goNext}>
                  <p>Przejdź dalej</p>
                </Card>
              </div>
            </div>
            }
          </CSSTransitionGroup>
        </div>
        }
      </CSSTransitionGroup>
    );
  }
}
