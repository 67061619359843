import React, { Component } from 'react';
import PropTypes from 'prop-types'
import classNames from 'classnames';

import AnimatedElement from "../AnimatedElement/AnimatedElement";
import Sounds from "../../lib/Sounds";

import './PointsBar.scss';

class PointsBar extends Component {
  secondsTimeout;

  static propTypes = {
    'visible': PropTypes.bool,

    'hidePoints': PropTypes.bool,
    'points': PropTypes.number,
    'maxPoints': PropTypes.number,
    'negativePointsAllowed': PropTypes.bool,

    'hideClock': PropTypes.bool,
    'timeLimit': PropTypes.number,
    'time': PropTypes.number,
    'clockRunning': PropTypes.bool,
    'clockWarningSeconds': PropTypes.number,
    'clockId': PropTypes.number,

    'onSecondPassed': PropTypes.func,
    'onTimeRanOut': PropTypes.func,

    'instruction': PropTypes.string,
  };

  static defaultProps = {
    'visible': true,

    hidePoints: false,
    points: 0,
    negativePointsAllowed: false,
    instruction: '',

    'hideClock': false,
    'timeLimit': 0,
    'time': null,
    'clockRunning': false,
    'onTimeRanOut': () => {},
    'onSecondPassed': () => {},
    'clockWarningTime': -1,
    'clockId': 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      time: props.timeLimit,
    };


    if (this.props.clockRunning) {
      this.startSecondsTimer();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clockId !== this.props.clockId) {
      if (this.props.clockRunning) {
        this.startSecondsTimer()
      }

      this.setState({
        time: this.props.timeLimit
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.clockRunning && !this.props.clockRunning) {
      this.startSecondsTimer();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.secondsTimeout);
  }

  render() {
    let time = this.props.time ? this.props.time : this.state.time;
    let points = this.getPoints();
    let pointsMax = this.props.maxPoints ?  ` | ${this.props.maxPoints}` : '';
    let formattedPoints = `${points}${pointsMax}`;
    let minutes = Math.floor(time / 60);
    let seconds = time % 60;
    let formattedTime = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    let clockWarning = (minutes === 0) && (seconds <= this.props.clockWarningSeconds);

    const visible = this.props.visible && !(this.props.hideClock && this.props.hidePoints && this.props.instruction === "");

    return (
      <AnimatedElement className="PointsBar" visible={visible} animation={AnimatedElement.AnimationTypes.slideLeft}>
        <div className="left-filler" />
        <div className={classNames('points-bar-body', {'with-clock': !this.props.hideClock}, {'with-cup': !this.props.hidePoints})}>
          <div className={classNames("points-bar-with-icon", {"cup-icon": !this.props.hidePoints})}>
            {!this.props.hidePoints && formattedPoints}
          </div>
          <div className="points-bar-content">
            {this.props.instruction}
          </div>
          <div className={classNames(
            "points-bar-with-icon",
            {
              "clock-icon": !this.props.hideClock,
              "clock-warning": clockWarning,
              "clock-warning-stopped": clockWarning & !this.props.clockRunning
            })}
          >
            {!this.props.hideClock && formattedTime}
          </div>
        </div>
      </AnimatedElement>
    );
  }
  startSecondsTimer = () => {
    clearTimeout(this.secondsTimeout);
    this.secondsTimeout = setTimeout(this.secondPassed, 1000);
  };

  secondPassed = () => {
    if (this.props.clockRunning) {
      if (this.state.time > 0) {
        this.props.onSecondPassed(this.state.time - 1);
        this.setState((prevState) => {
          return {
            time: prevState.time - 1,
          }
        },
        () => this.startSecondsTimer());
      } else {
        this.timeRanOut();
      }
    }
  };

  timeRanOut = () => {
    Sounds.time_out.play();
    this.props.onTimeRanOut();
  };

  getPoints = () => {
    if (this.props.negativePointsAllowed) {
      return this.props.points
    } else {
      return this.props.points < 0 ? 0 : this.props.points
    }
  }
}



export default PointsBar;
