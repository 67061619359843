import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';
import Draggable from 'react-draggable';
import _ from 'underscore';

import Card from "components/Card";
import { WIDTH_EMS } from "controllers/SprintController";

import './css/MatchExercise.css'
import PointsBar from "components/PointsBar";

const CARD_WIDTH = 20;
const ANIMATION_SPEED = 1000;
const TIME_LIMIT = 30;

class MatchExercise extends Component {
  answerImageCards = {};

  static propTypes = {
    question: PropTypes.string,
    answers: PropTypes.object,
    answersType: PropTypes.string,
    goNextAction: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: true,

      clockRunning: false,
      timeRanOut: false,
      gameFinished: false,
      points: 0,
      playing: true,

      answers: props.answers,
      currentTextCardIndex: 0,

      changed: 0,
    };

    this.state.answers['image'] = _.shuffle(this.state.answers['image']);
    this.state.answers['text'] = _.shuffle(this.state.answers['text']);

    for (let type of Object.keys(this.state.answers)) {
      let answers = this.state.answers[type];
      for (let id in answers) {
        if (answers.hasOwnProperty(id)) {
          answers[id] = _.extend(answers[id], {
            active: true,
            visible: true,
            index: id,
            type: type,
            success: false,
            showFeedback: false,
            timeout: null,
          });
        }
      }
    }

    this.answerChosen = this.answerChosen.bind(this);
    this.goNext = this.goNext.bind(this);

    this._goNext = this._goNext.bind(this);
  }

  componentDidMount() {
    this._updateAnswersPosition()
  }

  componentDidUpdate() {
    this._updateAnswersPosition()
  }

  _updateAnswersPosition = () => {
    for (let index in this.answerImageCards) {
      let answerImageCard = this.answerImageCards[index];
      let cardRect = answerImageCard.card.DOM.getBoundingClientRect();

      answerImageCard['position'] = {
        topLeft: {
          x: cardRect.x,
          y: cardRect.y
        },
        bottomRight: {
          x: cardRect.x + cardRect.width,
          y: cardRect.y + cardRect.height
        }
      }
    }
  };

  answerChosen(answer, imageAnswer) {
    if (!this.state.playing) {
      return;
    }

    if (answer.id === imageAnswer.id) {
      // correct
      this.setState((prevState) => {
        let timeout = setTimeout(this.hideAnswer.bind(this, imageAnswer), 2000);

        prevState.answers[answer.type][answer.index]['visible'] = false;
        prevState.answers[answer.type][answer.index]['active'] = false;

        prevState.answers[imageAnswer.type][imageAnswer.index]['active'] = false;
        prevState.answers[imageAnswer.type][imageAnswer.index]['success'] = true;
        prevState.answers[imageAnswer.type][imageAnswer.index]['showFeedback'] = true;
        prevState.answers[imageAnswer.type][imageAnswer.index]['timeout'] = timeout;

        return {
          points: prevState.points + 2,
          answers: prevState.answers,
        }
      });

      new Audio('/sounds/success.mp3').play();
    } else {
      // error
      this.setState((prevState) => {
        let timeout = setTimeout(this.hideFeedback.bind(this, imageAnswer), 2000);

        prevState.answers[imageAnswer.type][imageAnswer.index]['active'] = false;
        prevState.answers[imageAnswer.type][imageAnswer.index]['showFeedback'] = true;
        prevState.answers[imageAnswer.type][imageAnswer.index]['timeout'] = timeout;

        return {
          answers: prevState.answers,
        }
      });

      new Audio('/sounds/error.mp3').play();
    }
  }

  hideAnswer = (answer) => {
    this.setState((prevState) => {
      prevState.answers[answer.type][answer.index]['visible'] = false;

      let currentTextCardIndex = prevState.currentTextCardIndex + 1;

      return {
        answers: prevState.answers,
        finished: currentTextCardIndex >= prevState.answers['text'].length,
        currentTextCardIndex: currentTextCardIndex,
      }
    });
  };

  hideFeedback = (answer) => {
    this.setState((prevState) => {
      prevState.answers[answer.type][answer.index]['active'] = true;
      prevState.answers[answer.type][answer.index]['showFeedback'] = false;

      return {
        answers: prevState.answers,
      }
    });
  };

  goNext() {
    new Audio('/sounds/click.mp3').play();
    this.setState({
      visible: false,
    });
    setTimeout(this._goNext, ANIMATION_SPEED);
  }

  _goNext() {
    this.props.goNextAction(this.state.points);
  }

  timeRanOut = () => {
    this.setState((prevState) => {
      for (let type in prevState.answers) {
        if (prevState.answers.hasOwnProperty(type)) {
          for (let answer of prevState.answers[type]) {
            answer.active = false;
            answer.visible = false;
          }
        }
      }

      return {
        playing: false,
        finished: true,
        timeRanOut: true,
        clockRunning: false,
      }
    });
  };

  answerDragged = (event, target, answer) => {
    this.setState({
      clockRunning: true,
    });

    let node = target.node.getBoundingClientRect();
    let [x, y, width, height] = [node.x, node.y, node.width, node.height];
    let corners = [
      { x: x, y: y },
      { x: x + width, y: y },
      { x: x, y: y + height },
      { x: x + width, y: y + height },
    ];

    let draggedAnswer = this._findImageCard(event.clientX, event.clientY);
    if (!draggedAnswer) {
      draggedAnswer = this._findCrossingImageCard(corners);
    }

    if (draggedAnswer) {
      this.answerChosen(answer, draggedAnswer);
    }
  };

  _findImageCard = (x, y)  => {
    for (let imageCardId of Object.keys(this.answerImageCards)) {
      let imageCard = this.answerImageCards[imageCardId];
      if (imageCard.answer.active && imageCard.position) {
        if ((imageCard.position.topLeft.x < x && x < imageCard.position.bottomRight.x)
          && (imageCard.position.topLeft.y < y && y < imageCard.position.bottomRight.y)
        ) {
          return imageCard.answer;
        }
      }
    }

    return false;
  };

  _findCrossingImageCard = (corners) => {
    let [answer, newAnswer] = [false, false];
    for (let corner of corners) {
      newAnswer = this._findImageCard(corner.x, corner.y);
      if (answer && newAnswer && newAnswer.id !== answer.id) {
        answer = false;
        break;
      } else if (newAnswer) {
        answer = newAnswer;
      }
    }

    return answer;
  };

  render() {
    let answers = this.state.answers;
    let answerRows = [];
    let itemsPerRow = 3;

    let currentRow = 0;
    let cardStyle = {
      width: CARD_WIDTH + 'em',
    };

    let type = 'image';
    let rows = Math.ceil(answers[type].length / 3);
    for (let row = 0; row < rows; row++) {
      let answersForRow = answers[type].slice(row * itemsPerRow, (row + 1) * itemsPerRow).map((answer, rowIndex) => {
        let index = row * itemsPerRow + rowIndex;
        let card = '';

        if (type === 'text') {
          card =
            <Draggable
              bounds='.answers-container'
              handle='.handle'
              defaultPosition={{x: 0, y: 0}}
              position={{x: 0, y: 0}}
              onStop={(e, t, c) => this.answerDragged(e, t, answer)}
              key={index}>
              <div className='handle' style={cardStyle}>
                <Card classes={type} answer={answer} side={answer.side} hidden={!answer.visible} index={index}>
                  <p>
                    {answer.content}
                  </p>
                </Card>
              </div>
            </Draggable>
        } else if (type === 'image') {
          let imageStyle = {
            backgroundImage: 'url("/images/match/' + answer.src + '")',
          };
          card = (
            <Card classes={'answer-image' + (answer.showFeedback ? ' show-feedback' : '')} style={cardStyle}
                  answer={answer} side={answer.side} hidden={!answer.visible} index={index}
                  key={index}
                  saveDOM={true}
                  ref={(element) => {this.answerImageCards[index] = {card: element, answer: answer}}}
            >
              <div className="front">
                <div className='image' style={imageStyle} />
              </div>
              <div className="back">
                {answer.success &&
                <div className='feedback success'>
                  <div className="pure-u-2-5 icon">
                    <div className="vertical-helper"/>
                    <div className="feedback-icon"><i className="fas fa-check" /></div>
                  </div>
                  <div className="pure-u-3-5 text">
                    <div className="vertical-helper" />
                    <div className="feedback-text">
                      Świetnie!
                    </div>
                  </div>
                </div>
                }
                {!answer.success &&
                <div className='feedback error'>
                  <div className="pure-u-2-5 icon">
                    <div className="vertical-helper"/>
                    <div className="feedback-icon"><i className="fas fa-times" /></div>
                  </div>
                  <div className="pure-u-3-5 text">
                    <div className="vertical-helper" />
                    <div className="feedback-text">
                      Upss...<br /><span className="smaller">Spróbuj jeszcze raz</span>
                    </div>
                  </div>
                </div>
                }
              </div>
            </Card>
          )
        }

        return [
          card,
          ' ',
        ];
      });

      let rowItems = answersForRow.length;
      if (rowItems < itemsPerRow) {
        let space = (WIDTH_EMS - itemsPerRow * CARD_WIDTH) / (itemsPerRow - 1);
        let fakeWidth = ((itemsPerRow - rowItems) * (CARD_WIDTH + space)) / 2;
        let fakeStyle = {
          width: fakeWidth + 'em'
        };
        answersForRow.unshift([
          <div className='fake-element' style={fakeStyle} key={100}/>,
        ]);
        answersForRow[answersForRow.length-1].pop(); // So fake-element sticks to last element
        answersForRow.push([
          <div className='fake-element' style={fakeStyle} key={101} />,
        ]);
      }
      let answersRow = (
        <div className={'answers-row pure-u-1-1' + (rowItems < itemsPerRow ? ' uneven' : '')} key={currentRow}>
          {answersForRow}
        </div>
      );
      answerRows.push(answersRow);
      currentRow++;
    }

    let textCard = '';
    if (this.state.currentTextCardIndex < this.state.answers['text'].length) {
      let answer = this.state.answers['text'][this.state.currentTextCardIndex];
      textCard = (
        <Draggable
          bounds='.answers-container'
          handle='.handle'
          defaultPosition={{x: 0, y: 0}}
          position={{x: 0, y: 0}}
          onStop={(e, t, c) => this.answerDragged(e, t, answer)}
        >
          <div className='handle' style={cardStyle}>
            <Card classes='text' answer={answer} side={answer.side} hidden={!answer.visible}>
              <p>
                {answer.content}
              </p>
            </Card>
          </div>
        </Draggable>
      );
    }

    return (
      <CSSTransitionGroup
        transitionName="example"
        transitionAppear={true}
        transitionAppearTimeout={ANIMATION_SPEED}
        transitionEnter={true}
        transitionEnterTimeout={ANIMATION_SPEED}
        transitionLeave={true}
        transitionLeaveTimeout={ANIMATION_SPEED}>
        {this.state.visible &&
        <div className="MatchExercise">
          <div className="pure-g points-bar-container">
            <div className="pure-u-1-1">
              <PointsBar
                points={this.state.points} maxPoints={10}
                timeLimit={TIME_LIMIT} clockRunning={this.state.clockRunning} onTimeRanOut={this.timeRanOut}
                instruction={this.props.instruction}
              />
            </div>
          </div>
          <div className="pure-g answers-container">
            <CSSTransitionGroup
              transitionName="example"
              transitionAppear={true}
              transitionAppearTimeout={ANIMATION_SPEED}
              transitionEnter={true}
              transitionEnterTimeout={ANIMATION_SPEED}
              transitionLeave={true}
              transitionLeaveTimeout={ANIMATION_SPEED}>
              {this.state.gameFinished &&
              <Card classes='timeFinished pure-u-1-1'>
                <h1>Koniec czasu!</h1>
              </Card>
              }
            </CSSTransitionGroup>
            {answerRows}
            <div className={'answers-row pure-u-1-1 center'} key={currentRow}>
              {textCard}
            </div>
          </div>
          <CSSTransitionGroup
            transitionName="example"
            transitionEnter={true}
            transitionEnterTimeout={ANIMATION_SPEED}
            transitionLeave={true}
            transitionLeaveTimeout={ANIMATION_SPEED}>
            {this.state.gameFinished &&
            <div className="pure-g buttons-container">
              <div className="pure-u-1-1 center">
                <Card classes="next-button" onClick={this.goNext}>
                  <p>Przejdź dalej</p>
                </Card>
              </div>
            </div>
            }
          </CSSTransitionGroup>
        </div>
        }
      </CSSTransitionGroup>
    );
  }
}

export default MatchExercise;
