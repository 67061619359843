import React, {Component} from "react";
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Overlay from "../Overlay/Overlay";
import AnimatedElement from "../AnimatedElement/AnimatedElement";

import './FeedbackCard.scss';

const DEFAULT_SUCCESS = "Świetnie! To prawidłowa odpowiedź.";
const DEFAULT_FAILURE = "Niestety, ta odpowiedź jest nieprawidłowa.";

export default class FeedbackCard extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    content: PropTypes.string,
    successful: PropTypes.bool,

    useDefaultFeedback: PropTypes.bool,
  };

  static defaultProps = {
    successful: false,
    useDefaultFeedback: true,
  };

  render () {
    let {visible, content, successful, useDefaultFeedback} = this.props;

    if (!content && useDefaultFeedback) {
      if (successful) {
        content = DEFAULT_SUCCESS;
      } else {
        content = DEFAULT_FAILURE;
      }
    }

    return (
      <AnimatedElement visible={visible && !!content} className="FeedbackCard" durationMs={1000} zIndex={100}>
        <Overlay zIndex={100} />
        <div
          className={classNames(
            "feedback",
            {
              "success": successful,
            })
          }
        >
            <p>{content}</p>
        </div>
      </AnimatedElement>
    )
  }
}