import React, {useState, useEffect, useRef, useCallback} from 'react';
import classNames from 'classnames';

import Card from "components/Card/Card";
import PlayArea from "lib/PlayArea";

import targetIcon from "../images/icons/target.svg";
import executionIcon from "../images/icons/execution.svg";
import choicesIcon from "../images/icons/choices.svg";
import pointsIcon from "../images/icons/points.svg";

import "./ExerciseInstructions.scss";

export const INSTRUCTION_STEP_TYPES = {
  TARGET: "TARGET",
  EXECUTION: "EXECUTION",
  CHOICES: "CHOICES",
  POINTS: "POINTS",
};

const STEP_IMAGES = {
  [INSTRUCTION_STEP_TYPES.TARGET]: targetIcon,
  [INSTRUCTION_STEP_TYPES.EXECUTION]: executionIcon,
  [INSTRUCTION_STEP_TYPES.CHOICES]: choicesIcon,
  [INSTRUCTION_STEP_TYPES.POINTS]: pointsIcon,
};
const STEP_TIME_MS = 2500;

let timeout;

export default function ExerciseInstructions({name, steps, imageHorizontal, imageVertical, onFinish}) {
  const [currentStep, setCurrentStep] = useState(0);
  const currentStepRef = useRef(currentStep);
  currentStepRef.current = currentStep;

  const goToNextStep = useCallback(() => {
    if (currentStepRef.current >= Object.values(steps).length) {
      onFinish();
    } else {
      const nextStep = currentStepRef.current + 1;
      setCurrentStep(nextStep);
    }
  }, [onFinish, steps]);

  useEffect(() => {
    timeout = setTimeout(goToNextStep, currentStep === 0 ? 500 : STEP_TIME_MS);

    return () => {
      clearTimeout(timeout);
    }
  }, [currentStep, goToNextStep]);

  return <Card className="ExerciseInstructions">
    <div className="name">
      {name}
    </div>
    <div className="content">
      <img src={PlayArea.isVertical() ? imageVertical : imageHorizontal} alt="Ćwiczenie" className="exercise-image"/>
      <div className="instructions">
        {Object.entries(steps).map(([type, content], index) => (
          <ExerciseInstructionStep key={index}
            type={type}
            content={content}

            visible={index < currentStep}
          />
        ))}
      </div>
    </div>
  </Card>
}

function ExerciseInstructionStep({type, content, visible}) {
  return <div className={classNames("ExerciseInstructionStep", {"not-shown": !visible})}>
    <img src={STEP_IMAGES[type]} alt="Obrazek do kroku"/>
    <p>{content}</p>
  </div>
}