import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';

import { QuestionAnswerCard } from "components/AnswerCard";
import Card from "components/Card";

import './css/QuestionExercise.scss'
import PointsBar from "components/PointsBar";

const ANIMATION_SPEED = 1000;

class QuestionExercise extends Component {

  static propTypes = {
    question: PropTypes.string,
    answers: PropTypes.array,
    answersType: PropTypes.string,
    goNextAction: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: true,

      gameFinished: false,
      points: 10,
      playing: true,

      answers: props.answers,

      changed: 0,
    };

    for (let answer of this.state.answers) {
      answer.visible = true;
      answer.side = 'front';
    }

    this.answerChosen = this.answerChosen.bind(this);
    this.goNext = this.goNext.bind(this);

    this._goNext = this._goNext.bind(this);
  }

  createQuestionMarkup() {
    return {__html: '<div>' + this.props.question + '</div>'};
  }

  answerChosen(answer, key, event) {
    if (!this.state.playing) {
      return;
    }

    // event.target.parentElement.className += ' flip';

    if (answer.correct) {
      this.setState((prevState) => {
        prevState.answers[key].side = 'back';
        return {
          playing: false,
          finished: true,
          answers: prevState.answers,
        }
      });

      new Audio('/sounds/success.mp3').play();
    } else {
      this.setState((prevState) => {
        prevState.answers[key].side = 'back';
        return {
          points: prevState.points - 2,
          answers: prevState.answers,
        }
      });

      new Audio('/sounds/error.mp3').play();

      let hideAnswer = () => {
        this.setState((prevState) => {
          prevState.answers[key].visible = false;
          return {
            changed: prevState.changed + 1,
            answers: prevState.answers,
          }
        });
      };

      setTimeout(hideAnswer, 2000);
    }
  }

  goNext() {
    new Audio('/sounds/click.mp3').play();
    this.setState({
      visible: false,
    });
    setTimeout(this._goNext, ANIMATION_SPEED);
  }

  _goNext() {
    this.props.goNextAction(this.state.points);
  }

  render() {
    let answers = null;
    if (this.props.answersType === 'text') {
      answers = this.state.answers.map((answer, i) => {
        return <Card classes="answer" key={i}><p>{ answer.content }</p></Card>
      })
    } else if (this.props.answersType === 'image') {
      answers = this.state.answers.map((answer, i) => {
        let columns = 3;

        return <QuestionAnswerCard
          answer={answer}

          side={answer.side}
          index={i}
          columns={columns}

          clickCallback={this.answerChosen}

          key={i}
        />
      })
    }

    return (
      <CSSTransitionGroup
        transitionName="example"
        transitionAppear={true}
        transitionAppearTimeout={ANIMATION_SPEED}
        transitionEnter={true}
        transitionEnterTimeout={ANIMATION_SPEED}
        transitionLeave={true}
        transitionLeaveTimeout={ANIMATION_SPEED}>
        {this.state.visible &&
        <div className="QuestionExercise">
          <div className="pure-g points-bar-container">
            <div className="pure-u-1-1">
              <PointsBar points={this.state.points} maxPoints={10} hideClock={true}/>
            </div>
          </div>
          <div className="pure-g question-container">
            <div className="pure-u-1-1">
              <Card classes="question">
                <p dangerouslySetInnerHTML={this.createQuestionMarkup()}/>
              </Card>
            </div>
          </div>
          <div className="pure-g answers-container">
            {answers}
          </div>
          <CSSTransitionGroup
            transitionName="example"
            transitionEnter={true}
            transitionEnterTimeout={ANIMATION_SPEED}
            transitionLeave={true}
            transitionLeaveTimeout={ANIMATION_SPEED}>
          {this.state.gameFinished &&
            <div className="pure-g buttons-container">
              <div className="pure-u-1-1 center">
                <Card classes="next-button" onClick={this.goNext}>
                  <p>Przejdź dalej</p>
                </Card>
              </div>
            </div>
          }
          </CSSTransitionGroup>
        </div>
        }
      </CSSTransitionGroup>
    );
  }
}

export default QuestionExercise;
